export const getUrlPrivateSite = () => {
    const isBrowser = typeof window !== "undefined";
    const privateSite = process.env.GATSBY_URL_INVESTORS;
    const countryCode = (
        isBrowser ? window.domainData.countryCode : ""
    ).toLowerCase();
    return privateSite?.replace("{code}", countryCode);
};

export const getUrlRecoverPassword = () => {
    const url = getUrlPrivateSite();
    return `${url}/recuperar-contrasena`;
};

export const getUrlCreateAccount = () => {
    const url = getUrlPrivateSite();
    return `${url}/registro`;
};

export const getUrlLogin = () => {
    return getUrlPrivateSite();
};

export const getUrlBalanceCumplo = () => {
    const url = getUrlPrivateSite();
    return `${url}/saldo-cumplo`;
};
