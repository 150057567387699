import { graphql, useStaticQuery } from 'gatsby'

const GetProductCardsFeaturesPE = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "Tarjetas de productos"}, node_locale: {eq: "es-PE"}}
    ) {
      nodes {
        features {
          image {
            file {
              url
            }
          }
          title
          description
          body {
            internal {
              content
            }
          }
          callToAction {
            text
            link
          }
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetProductCardsFeaturesPE
