import { graphql, useStaticQuery } from 'gatsby'

const GetNosotrosFeaturesMX = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
  allContentfulFeatures(
    filter: {name: {eq: "Nosotros"}, node_locale: {eq: "es-MX"}}
  ) {
    nodes {
      features {
        icon
        description
        body {
          internal {
            content
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
}

        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetNosotrosFeaturesMX
