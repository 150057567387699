/* eslint-disable camelcase */

export const getCookie = (cname) => {
 if (typeof document !== 'undefined') {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
   let c = ca[i]
   while (c.charAt(0) === ' ') {
    c = c.substring(1)
   }
   if (c.indexOf(name) === 0) {
    return c.substring(name.length, c.length)
   }
  }
  return ''
 }
 return ''
}
  
export const isLoggedMX = () => {
 const tokenExpiration = getCookie('tkn_exp')
 if (tokenExpiration) {
  if (Number(tokenExpiration) > Date.now()) {
   return true
  }
  return false
 }
 return false
}

export default class Validations {
    updateInput = (e) => {
     const attribute = e.target.name
     const { value } = e.target
     return {
      [attribute]: value,
     }
    };
  
    validatePhone = (value) => {
     const regexPhone = new RegExp(/^\+569[0-9]{8}[\s]*$/)
     const prependedNumber = value[0] === '9' ? `+56${value}` : value
     if ((prependedNumber !== '' && !regexPhone.test(prependedNumber)) || prependedNumber === '') {
      return {
       error_phone: true,
      }
     }
     return {
      error_phone: false,
     }
    };

    validatePhonePeru = (value) => {
     const regexPhone = new RegExp(/^[0-9]{9}[\s]*$/)
     const prependedNumber = value
     if ((prependedNumber !== '' && !regexPhone.test(prependedNumber)) || prependedNumber === '') {
      return {
       error_phonePeru: true,
      }
     }
     return {
      error_phonePeru: false,
     }
    };
  
    validateFullname = (attribute, value) => {
     const regexFullname = new RegExp(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
     )
     if ((value !== '' && !(value.length < 3) && !regexFullname.test(value)) || value === '') {
      return {
       [`error_${attribute}`]: true,
      }
     }
     return {
      [`error_${attribute}`]: false,
     }
    };
  
    validatePassword = (attribute, value) => {
     if (value === '') {
      return {
       [`error_${attribute}`]: true,
      }
     }
     return {
      [`error_${attribute}`]: false,
     }
    };
  
    validateNewPassword = (attribute, value) => {
     if (!attribute || !value) {
      return {
       error: true,
      }
     }
     const regexFullname = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[Âº!"Â·$%&/()=?Â¿Â¡'^*Â¨Ã‡;:_,.Â´Ã§`+@+#)(=._-]+)(?=.{8,})/,
     )
     if ((value !== '' && !regexFullname.test(value)) || value === '') {
      return {
       [`error_${attribute}`]: true,
      }
     }
     return {
      error: true,
     }
    };
  
    validatePasswordConfirmation = (attribute, values) => {
     if (!values || !attribute) {
      return {
       error: true,
      }
     }
     if (values.password !== values.confirmation || values.password === '' || values.confirmation === '') {
      return {
       [`error_${attribute}`]: true,
      }
     }
     if (values.password === values.confirmation && values.password !== '' && values.confirmation !== '') {
      return {
       [`error_${attribute}`]: false,
      }
     }
     return {
      error: true,
     }
    };
  
    validateEmail = (value) => {
     const regexMail = new RegExp(
      /^([a-zA-Z0-9._-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
     )
     if ((value !== '' && !regexMail.test(value)) || value === '') {
      return {
       error_email: true,
      }
     }
     return {
      error_email: false,
     }
    };
  
    validatesRut = (value) => {
     const regexId = new RegExp(/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/)
     if ((value !== '' && !regexId.test(value)) || value === '') {
      return {
       error_document_number: true,
      }
     }
     return {
      error_document_number: false,
     }
    };
  
    validateOthersFields = (attribute, value) => {
     if (value === '') {
      return {
       [`error_${attribute}`]: true,
      }
     }
     return {
      [`error_${attribute}`]: false,
     }
    };
  
    validateCheckbox = (attribute, value) => {
     if (value === false) {
      return {
       [`error_${attribute}`]: true,
      }
     }
     return {
      [`error_${attribute}`]: false,
     }
    };
  
    // Validations for each field on the form
    validateField = (input_value) => {
     const attribute = Object.entries(input_value)[0][0]
     const value = Object.entries(input_value)[0][1]
  
     switch (attribute) {
     case 'fullname':
     case 'first_name':
     case 'last_name':
     case 'firstname':
     case 'lastname':
      return this.validateFullname(attribute, value)
     case 'phone':
      return this.validatePhone(value)
     case 'phonePeru':
      return this.validatePhonePeru(value)
     case 'email':
      return this.validateEmail(value)
     case 'document_number':
      return this.validatesRut(value)
     case 'password':
      return this.validatePassword(attribute, value)
     case 'new_password':
      return this.validateNewPassword(attribute, value)
     case 'password_confirmation':
      return this.validatePasswordConfirmation(attribute, value)
     case 'monthly_billing':
     case 'customer_type':
     case 'does_factoring':
     case 'financing_type':
     case 'enterprise':
     case 'enterprise_name':
     case 'business_name':
     case 'fantasy_name':
     case 'enterprise_activity':
     case 'country':
     case 'type':
      return this.validateOthersFields(attribute, value)
     case 'terms_conditions':
      return this.validateCheckbox(attribute, value)
     default:
      return null
     }
    };
  
    validatorsForm = (state) => {
     const formErrors = {
     }
     if (state.fullname === '' || state.error_fullname === true) {
      formErrors.error_fullname = true
     }
     if (state.firstname === '' || state.error_firstname === true) {
      formErrors.error_firstname = true
     }
     if (state.lastname === '' || state.error_lastname === true) {
      formErrors.error_lastname = true
     }
     if (state.enterprise_name === '' || state.error_enterprise_name === true) {
      formErrors.error_enterprise_name = true
     }
     if (state.enterprise_activity === '' || state.error_enterprise_activity === true) {
      formErrors.error_enterprise_activity = true
     }
     if (state.document_number === '' || state.error_document_number === true) {
      formErrors.error_document_number = true
     }
     if (state.email === '' || state.error_email === true) {
      formErrors.error_email = true
     }
     if (
      state.phone === ''
        || state.phone === '+569'
        || state.error_phone === true
     ) {
      formErrors.error_phone = true
     }
     if (
      state.phonePeru === ''
        || state.phonePeru === ''
        || state.error_phonePeru === true
     ) {
      formErrors.error_phonePeru = true
     }
     if (state.monthly_billing === '' || state.error_monthly_billing === true) {
      formErrors.error_monthly_billing = true
     }
     if (state.customer_type === '' || state.error_customer_type === true) {
      formErrors.error_customer_type = true
     }
     if (state.does_factoring === '' || state.error_does_factoring === true) {
      formErrors.error_does_factoring = true
     }
     if (state.financing_type === '' || state.error_financing_type === true) {
      formErrors.error_financing_type = true
     }
     if (state.enterprise === '' || state.error_enterprise === true) {
      formErrors.error_enterprise = true
     }
     return formErrors
    };
  
    addRegister = async (data, apiCallUrl) => {
     const response = await fetch(apiCallUrl, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
       Accept: 'application/json',
       'Content-Type': 'application/json',
      },
     })
     const { ok, status } = response
     if (ok) {
      return {
       status,
       loading: false,
       json: await response.json(),
      }
     }
     return {
      error: true,
      status,
      json: await response.json(),
     }
    };
}
