import React from "react";
import PropTypes from "prop-types";
import Card from '@mui/material/Card';
import { Box, Typography, useMediaQuery } from "@mui/material";

const CategoriaPregunta = ({
 titulo,
 descripcion,
 icono,
 cabecera
}) => {

 const enSM = useMediaQuery("(max-width:833px)");

 const styles = {
  card: {
   borderRadius: cabecera ? "20px 20px 0px 0px" : "20px",
   display: "flex",
   alignItems: "center",
   columnGap: 2,
   padding: enSM ? "24px 32px 32px" : 2,
   flexDirection: enSM ? "column" : "row",
   rowGap: 2,
  }
 }

 return (
  <Card sx={styles.card}>
   <Box sx={{ maxWidth: enSM ? "104px" : "144px", width: "100%", marginLeft: enSM ? 0 : 3 }}>
    <Box component="img" src={icono} />
   </Box>
   <Box sx={{ textAlign: enSM ? "center" : "left" }}>
    <Typography
     component="p"
     variant="h4"
     sx={{ marginBottom: enSM ? "8px" : "4px", fontWeight: 500 }}
     color="primary.main"
    >
     {titulo}
    </Typography>
    <Typography component="p" variant="h5">
     {descripcion}
    </Typography>
   </Box>
  </Card>
 );
};

CategoriaPregunta.propTypes = {
 titulo: PropTypes.string,
 descripcion: PropTypes.string,
 sinTarjeta: PropTypes.bool,
};

export { CategoriaPregunta };
export default CategoriaPregunta;