import { graphql, useStaticQuery } from 'gatsby'

const GetImpactoCommitmentsFeaturesUS = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures (
        filter: {name: {eq: "Impacto - Nuestros compromisos"}, node_locale: {eq: "en-US"}}
    ){
      nodes {
        title
        features {
          number
          title
          description
          image {
            file {
                url
            }
          }
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]

 return results

}

export default GetImpactoCommitmentsFeaturesUS
