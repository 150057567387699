import React from "react"
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Icon } from 'src/templates/atoms/Icon'
import useMediaQuery from '@mui/material/useMediaQuery';

const Indicator = ({icon, stat, label, size = "45px", lineBreak = false, variant = "1"}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))
 const splitLabel = label.split(" ");
 const styles = {
  iconContainer: {
   background: theme.palette.primary.main,
   borderRadius: "100%",
   width: "80px",
   height: "80px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
  }
 }
 return (
  <Box sx={{ display: "flex", flexDirection: 'column', justifyContent:"center", alignItems: "center"}}>
   {variant === "2" ? (
    <Box sx={ styles.iconContainer }>
     <Icon name={icon} height={size} color={variant === "2" ? theme.palette.text.secondary : theme.palette.primary.main }/>
    </Box>
   ) : (
    <Icon name={icon} height={size} color={variant === "2" ? theme.palette.text.secondary : theme.palette.primary.main } />
   )
   }
   <Box mb={3}/>
   <Typography color="primary.dark" variant="h2" align="center" >{stat}</Typography>
   <Box pb={md ? 1 : 0}/>
   <Typography color="primary.dark" variant="subtitle1" align="center">
    { lineBreak ? (
     splitLabel.map((line, index) => 
      <>
       {line}
       <br/>
      </>
     )
    ) : (
     label
    )}
   </Typography>
  </Box>
 )
}

export { Indicator }