import React, { Fragment } from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from "src/templates"

const Hero = ({title, body, callToAction, backgroundImagesDesktop, backgroundImagesMobile}) => {
 const theme = useTheme();
 const sm = useMediaQuery('(max-width:475px)')
 const md = useMediaQuery(theme.breakpoints.down('md'))
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  backgroundImageLeft: {
   position: "absolute",
   backgroundImage: !md ? `url(${backgroundImagesDesktop[0]?.file?.url})` : `url(${backgroundImagesMobile[0]?.file?.url})`,
   width: !md ? "36%" : "40%",
   left: 0,
   height: "calc(100vh - 140px)",
   backgroundPosition: "center left",
   backgroundRepeat: "no-repeat",
   backgroundSize: "contain",
   zIndex: -1,
   opacity: sm && 0.2
  },
  backgroundImageRight: {
   position: "absolute",
   backgroundImage: !md ? `url(${backgroundImagesDesktop[1]?.file?.url})` : `url(${backgroundImagesMobile[1]?.file?.url})`,
   width: !md ? "36%" : "40%",
   right: 0,
   height: "calc(100vh - 140px)",
   backgroundPosition: "center right",
   backgroundRepeat: "no-repeat",
   backgroundSize: "contain",
   zIndex: -1,
   opacity: sm && 0.2
  },
  backgroundContent: {
   marginTop: '160px',
   textAlign: "center",
   width: "100%",
   height: "380px",
   display: 'flex',
   justifyContent: "center",
   alignItems: "center",
   position: "relative",
   padding: "0px 12.5%",
  },
  backgroundContentCL: {
   marginTop: '50px',
   textAlign: "center",
   width: "100%",
   height: "380px",
   display: 'flex',
   justifyContent: "center",
   alignItems: "center",
   position: "relative",
   padding: "0px 12.5%",
  },
  subtitle: {
   maxWidth: lg ? "412px" : "564px",
   paddingBottom: 3,
   margin: "0 auto",
  },
  buttonsContainer: {
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   flexDirection: lg ? "column" : "row",
   marginTop: lg && "8px",
  },
  separator: {
   display: "inline-block",
   margin: lg ? "16px 0px" : "0px 32px",
   width: lg ? "80px" : "1px",
   height: lg ? "1px" : "80px",
   background: lg ? "none" : `${theme.palette.primary.main}`,
  },
 }

 return (
  <>
   <Box sx={styles.backgroundContent }>
    <Box sx={styles.backgroundImageLeft}/>
    <Box sx={styles.backgroundImageRight}/>
    <Box sx={styles.textsContainer}>
     <Typography variant="h1" color="primary.main">{title.split(",")[0]},</Typography>
     <Typography variant="h1" color="primary.dark" sx={{ pb: 3, maxWidth: "662px" }}>{title.split(",")[1]}</Typography>
     <Typography sx={styles.subtitle} variant="body1" color="primary.dark">{body}</Typography>
     <Box sx={styles.buttonsContainer}>
      {callToAction && callToAction.map((item, key) => (
       <Fragment key={key}>
        <Box sx={{display: "inline-block"}}>
         <a key={key} href={item?.link} style={{ textDecoration: 'none'}}>
          <Button variant={"contained"}>{item?.text}</Button>
         </a>
        </Box>
        { callToAction.length === 2 && key === 0 && (
         <Box sx={styles.separator} />
        )}
       </Fragment>
      ))}
     </Box>
    </Box>
   </Box>
  </>
 )
}

export { Hero }