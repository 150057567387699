import { createTheme } from '@mui/material/styles';
import breakpoints from './breakpoints'
const theme = createTheme(breakpoints);

const typography = {
 typography: {
  fontFamily: "Poppins",
  h1: {
   fontSize: 42,
   lineHeight: "48px",
   letterSpacing: 0,
   fontWeight: 600,
   [theme.breakpoints.down("md")]: {
    fontSize: 32,
    lineHeight: "34px",
   },
  },
  h2: {
   fontSize: 34,
   lineHeight: "40px",
   letterSpacing: 0,
   fontWeight: 500,
   [theme.breakpoints.down("md")]: {
    fontSize: 24,
    lineHeight: "30px",
   },
  },
  h3: {
   fontSize: 28,
   lineHeight: "34px",
   letterSpacing: 0,
   fontWeight: 600,
   [theme.breakpoints.down("md")]: {
    fontSize: 21,
    lineHeight: "27px",
   },
  },
  h4: {
   fontSize: 22,
   lineHeight: "28px",
   letterSpacing: 0,
   fontWeight: 400,
   [theme.breakpoints.down("md")]: {
    fontSize: 19,
    lineHeight: "25px",
   },
  },
  h5: {
   fontSize: 19,
   lineHeight: "25px",
   letterSpacing: 0,
   fontWeight: 400,
   [theme.breakpoints.down("md")]: {
    fontSize: 18,
    lineHeight: "24px",
   },
  },
  h6: {
   fontSize: 19,
   lineHeight: "25px",
   letterSpacing: 0,
   fontWeight: 600,
   [theme.breakpoints.down("md")]: {
    fontSize: 18,
    lineHeight: "24px",
   },
  },
  subtitle1: {
   fontSize: 18,
   lineHeight: "24px",
   letterSpacing: 0,
   fontWeight: 500,
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    lineHeight: "23px",
   },
  },
  subtitle2: {
   fontSize: 17,
   lineHeight: "23px",
   letterSpacing: 0,
   fontWeight: 500,
   [theme.breakpoints.down("md")]: {
    fontSize: 16,
    lineHeight: "22px",
   },
  },
  body1: {
   fontFamily: "Inter",
   fontSize: 16,
   lineHeight: "24px",
   letterSpacing: 0,
   fontWeight: 400,
   [theme.breakpoints.down("md")]: {
    fontSize: 15,
    lineHeight: "23px",
   },
  },
  body2: {
   fontFamily: "Inter",
   fontSize: 14,
   lineHeight: "17px",
   letterSpacing: 0,
   fontWeight: 400,
   [theme.breakpoints.down("md")]: {
    fontSize: 13,
    lineHeight: "16px",
   },
  },
  button: {
   fontSize: 14,
   lineHeight: "17px",
   letterSpacing: 0.8,
   fontWeight: 600,
   textTransform: 'uppercase',
   [theme.breakpoints.down("md")]: {
    fontSize: 13,
    lineHeight: "16px",
   },
  },
  caption: {
   fontFamily: "Inter",
   fontSize: 12,
   lineHeight: "15px",
   letterSpacing: 0,
   fontWeight: 500,
   [theme.breakpoints.down("md")]: {
    fontSize: 11,
    lineHeight: "14px",
   },
  },
  overline: {
   fontFamily: "Inter",
   lineHeight: "15px",
   fontSize: 12,
   letterSpacing: 0,
   fontWeight: 500,
   textTransform: 'uppercase',
   [theme.breakpoints.down("md")]: {
    fontSize: 11,
    lineHeight: "14px",
   },
   
  },
 },
};

export default typography;