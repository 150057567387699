/* eslint-disable no-tabs */
import { gql } from '@apollo/client'

export const GET_FUNDING_REQUESTS = gql`
query FundingRequests($page: Int!, $limit: Int!, $state: Int, $ordering: String) {
  fundingRequests (page: $page, limit: $limit, state: $state, ordering: $ordering) {
    count
    allCompleted
    results {
      empresa {
        historialCumplimiento {
          cantidad
          tipo
        }
      	id
        logo
        nombre_fantasia
      }
      operacion{
        id
        moneda
        monto_financiar
        plazo{
          type
          value
        }
        porcentaje_inversion
        score
        tasa_anual
        tipo_respaldo
        tir
      }
    }
  }
}
`

export const LOGIN = gql`
mutation login($email: String!, $password: String!) {
  login (email: $email, password: $password) {
    token
    user
  }
}
`

export const ADD_INVESTOR_PERU = gql`
mutation addInvestorPeru ( $firstname: String!, $lastname: String!, $email: String!, $phone: String!) {
  addInvestorPeru ( 
    firstname: $firstname,
    lastname: $lastname,
     email: $email,
    phone: $phone,
    pais: "Peru"
    ) 
}
`

export const LOGOUT = gql`
mutation logout {
  logout
}
`

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email: String!) {
  forgotPassword (email: $email) 
}
`
export const RESET_PASSWORD = gql`
mutation resetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword (token: $token, password: $password, passwordConfirmation: $passwordConfirmation) 
}
`

export const CHANGE_PASSWORD = gql`
mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword (currentPassword: $currentPassword, newPassword: $newPassword) 
}
`
export const CLIENT_IS_LOGGED_IN = gql`
  query ClientIsLoggedIn {
    isLoggedIn @client
  }
`

export const CONTACT_INVESTOR_PERU = gql`
mutation contactInvestorPeru 
( 
  $isFirstInvestment: Boolean!, 
  $firstname: String!,
  $lastname: String!,
  $dni: String!,
  $email: String!,
  $phone: String!
  $investmentId: Int!,
  $amount: Float!,
  $currency: String!,
) 
{
  contactInvestorPeru 
  ( 
    isFirstInvestment: $isFirstInvestment,
    firstname: $firstname,
    lastname: $lastname,
    dni: $dni,
    email: $email,
    phone: $phone,
    investmentId: $investmentId,
    amount: $amount,
    currency: $currency,
    pais: "Peru"
  ) 
}
`
