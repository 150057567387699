import { graphql, useStaticQuery } from 'gatsby'

const GetHeroNosotrosBannerCO = () => {

 const { allContentfulBanner } = useStaticQuery(graphql`
 {
    allContentfulBanner(
      filter: {name: {eq: "Hero - Nosotros"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        title
        body {
          internal {
            content
          }
        }
        backgroundDesktop {
          file {
            url
          }
        }
        backgroundMobile {
          file {
            url
          }
        }
      }
    }
  }
        `)
 const results = allContentfulBanner?.nodes[0]
   
 return results
   
}
   
export default GetHeroNosotrosBannerCO
   
   