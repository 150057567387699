import React, { useState } from "react";
import {
 Accordion,
 AccordionDetails,
 AccordionSummary,
 Typography,
 useMediaQuery,
 useTheme,
} from "@mui/material";
import {
 KeyboardArrowDownRounded,
 KeyboardArrowRightRounded,
} from "@mui/icons-material";

const Acordeon = ({
 titulo,
 tituloSecundario,
 tituloTerciario,
 TituloTypographyProps,
 TituloSecundarioTypographyProps,
 TituloTerciarioTypographyProps,
 children,
 AccordionProps,
 AccordionSummaryProps,
 AccordionDetailsProps,
}) => {
 const theme = useTheme();
 const enSM = useMediaQuery("(max-width:833px)");
 const [mostrarIconoExpansion, cambiarIcono] = useState(true);
 return (
  <Accordion
   sx={{ padding: 0, boxShadow: "0px 10px 4px rgba(0, 0, 0, 0.1)" }}
   {...AccordionProps}
   onChange={() => cambiarIcono(!mostrarIconoExpansion)}
  >
   <AccordionSummary
    expandIcon={
     mostrarIconoExpansion ? (
      <KeyboardArrowRightRounded fontSize="large" sx={{color: theme.palette.primary.dark}} />
     ) : (
      <KeyboardArrowDownRounded fontSize="large" sx={{color: theme.palette.primary.dark}} />
     )
    }
    sx={{
     backgroundColor: theme.palette.text.secondary,
     padding: "4px 43px",
     margin: 0,
     borderBottom: `1px solid ${theme.palette.primary.dark}30`,
     "&.Mui-expanded": {
      margin: 0,
     },
    }}
    {...AccordionSummaryProps}
   >
    <Typography
     color="primary.dark"
     sx={{
      width: tituloSecundario
       ? tituloTerciario
        ? "33%"
        : "50%"
       : "100%",
      flexShrink: 0,
     }}
     variant="subtitle1"
     {...TituloTypographyProps}
    >
     {titulo}
    </Typography>
    {tituloSecundario && (
     <Typography
      sx={{ width: tituloTerciario ? "33%" : "50%", flexShrink: 0 }}
      variant="subtitle1"
      color={theme.palette.info.main}
      {...TituloSecundarioTypographyProps}
     >
      {tituloSecundario}
     </Typography>
    )}

    {tituloTerciario && (
     <Typography
      variant="subtitle1"
      color={theme.palette.info.main}
      {...TituloTerciarioTypographyProps}
     >
      {tituloTerciario}
     </Typography>
    )}
   </AccordionSummary>
   <AccordionDetails
    {...AccordionDetailsProps}
    sx={{
     backgroundColor: "white",
     padding: enSM ? "40px 24px" : "40px 43px",
    }}
   >
    {children}
   </AccordionDetails>
  </Accordion>
 );
};

export { Acordeon };
export default Acordeon;
