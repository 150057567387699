import React, { useState, useEffect, useRef } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { ThemeProvider as ThemeProviderDS } from "@cumplo/design-system"
import { AnnouncementBar } from "@cumplo/design-system"
import Cookies from 'universal-cookie';
import { Location } from '@reach/router'

function ElevationScroll(props) {
 const { children } = props;
   
 return React.cloneElement(children, {
  elevation: 0,
 });
}

const ElevateAppBar = (props) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
 const { children, ...elevationProps } = props;
 const cookies = new Cookies();

 const [height, setHeight] = useState(0)
 const [open, setOpen] = useState(cookies.get('preventFraudAnnouncement'))
 const ref = useRef(null)

 useEffect(() => {
    if (ref?.current?.clientHeight) {
        setHeight(ref.current.clientHeight)
    }
    if (cookies.get('preventFraudAnnouncement') === "true") {
      setOpen(true)
    }
 })

 const setCloseAnnouncement = () => {
    cookies.set('preventFraudAnnouncement', false, { path: '/' })
    setOpen(false)
 }


 const triggerWithAnnouncement = useScrollTrigger({
    disableHysteresis: true,
    threshold: height,
   });

 return (
  <>
   <CssBaseline />
   <ElevationScroll {...elevationProps}>
    <AppBar style={{ 
        background: theme.palette.text.secondary,
        position: "fixed",
        top: process.env.GATSBY_REACT_APP_REGION === 'es-MX' && triggerWithAnnouncement && open === true ? -height : 0,
        transition: "all 0.5s ease"
    }}>
    {process.env.GATSBY_REACT_APP_REGION === 'es-MX' && (
      <Location>
         {({ location }) => ((location?.pathname !== "/prevencion-de-fraudes" && location?.pathname !== "/prevencion-de-fraudes/") && open === true) && 
            <ThemeProviderDS>
               <div ref={ref}>
                  <AnnouncementBar 
                  body="En Cumplo no solicitamos dinero previo a la contratación de sus productos. Conoce otros datos para "
                  linkTitle="prevenir fraudes."
                  linkUrl="/prevencion-de-fraudes"
                  onClose={setCloseAnnouncement}
                  />
               </div>
            </ThemeProviderDS>
         }
      </Location>
  )}
     <Toolbar sx={{padding: lg ? "16px 0px !important" : "12px 24px !important", boxShadow: triggerWithAnnouncement ? `0px 8px 24px ${theme.palette.primary.dark}20` : "none",}}>
      {children}
     </Toolbar>
    </AppBar>
   </ElevationScroll>
  </>
 );
}

export { ElevateAppBar }