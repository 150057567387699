import { graphql, useStaticQuery } from 'gatsby'

const GetTimelineFeaturesPE = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "Timeline"}, node_locale: {eq: "es-PE"}}
    ) {
      nodes {
        title
        features {
          title
          description
          image {
            file {
              url
            }
          }
          childrenContentfulFeatureBodyTextNode {
            body
          }
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetTimelineFeaturesPE
