import React from "react";
import PropTypes from "prop-types";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { ReactComponent as TwoCoin } from "src/assets/svg/icons/2-coin.svg";
import { ReactComponent as FourCoin } from "src/assets/svg/icons/4-coin.svg";
import { ReactComponent as Accept } from "src/assets/svg/icons/accept.svg";
import { ReactComponent as ArrowDown } from "src/assets/svg/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "src/assets/svg/icons/arrow-up.svg";
import { ReactComponent as Arrow } from "src/assets/svg/icons/arrow.svg";
import { ReactComponent as Buy } from "src/assets/svg/icons/buy.svg";
import { ReactComponent as Calendar } from "src/assets/svg/icons/calendar.svg";
import { ReactComponent as Cancel } from "src/assets/svg/icons/cancel.svg";
import { ReactComponent as CartLight } from "src/assets/svg/icons/cart-light.svg";
import { ReactComponent as Cash } from "src/assets/svg/icons/cash.svg";
import { ReactComponent as CheckDark } from "src/assets/svg/icons/check-dark.svg";
import { ReactComponent as CheckFill } from "src/assets/svg/icons/check-fill.svg";
import { ReactComponent as CheckGray } from "src/assets/svg/icons/check-gray.svg";
import { ReactComponent as CheckLight } from "src/assets/svg/icons/check-light.svg";
import { ReactComponent as Cross } from "src/assets/svg/icons/cross.svg";
import { ReactComponent as CurveDown } from "src/assets/svg/icons/curve-down.svg";
import { ReactComponent as CumploLogo } from "src/assets/svg/icons/cumplo-logo.svg";
import { ReactComponent as DeleteIcon } from "src/assets/svg/icons/delete.svg";
import { ReactComponent as DocumentCheck } from "src/assets/svg/icons/document-check.svg";
import { ReactComponent as DocumentDark } from "src/assets/svg/icons/document-dark.svg";
import { ReactComponent as DownloadLight } from "src/assets/svg/icons/download-light.svg";
import { ReactComponent as Enterprise } from "src/assets/svg/icons/enterprise.svg";
import { ReactComponent as EditIcon } from "src/assets/svg/icons/edit.svg";
import { ReactComponent as FilterWhite } from "src/assets/svg/icons/filter-white.svg";
import { ReactComponent as Filter } from "src/assets/svg/icons/filter.svg";
import { ReactComponent as Geo } from "src/assets/svg/icons/geo.svg";
import { ReactComponent as GiveMoney } from "src/assets/svg/icons/give-money.svg";
import { ReactComponent as GoUp } from "src/assets/svg/icons/go-up.svg";
import { ReactComponent as GoDown } from "src/assets/svg/icons/go-down.svg";
import { ReactComponent as Idea } from "src/assets/svg/icons/idea.svg";
import { ReactComponent as Info } from "src/assets/svg/icons/info.svg";
import { ReactComponent as Tech } from "src/assets/svg/icons/imac.svg";
import { ReactComponent as Like } from "src/assets/svg/icons/like.svg";
import { ReactComponent as Lock } from "src/assets/svg/icons/lock.svg";
import { ReactComponent as MagnifyingGlass } from "src/assets/svg/icons/magnifying-glass.svg";
import { ReactComponent as Mail } from "src/assets/svg/icons/mail.svg";
import { ReactComponent as Message } from "src/assets/svg/icons/message.svg";
import { ReactComponent as MoneyBag } from "src/assets/svg/icons/money-bag.svg";
import { ReactComponent as MoneyDelete } from "src/assets/svg/icons/money-delete.svg";
import { ReactComponent as MoneyGroup } from "src/assets/svg/icons/money-group.svg";
import { ReactComponent as MoneySuccess } from "src/assets/svg/icons/money-success.svg";
import { ReactComponent as OffDark } from "src/assets/svg/icons/off-dark.svg";
import { ReactComponent as Phone } from "src/assets/svg/icons/phone.svg";
import { ReactComponent as PieChartDark } from "src/assets/svg/icons/pie-chart-dark.svg";
import { ReactComponent as Pig } from "src/assets/svg/icons/pig.svg";
import { ReactComponent as PresentationStand } from "src/assets/svg/icons/presentation-stand.svg";
import { ReactComponent as PieChart } from "src/assets/svg/icons/pie-chart.svg";
import { ReactComponent as Refund } from "src/assets/svg/icons/refund.svg";
import { ReactComponent as ReturnPercent } from "src/assets/svg/icons/return-percent.svg";
import { ReactComponent as Shake } from "src/assets/svg/icons/shake.svg";
import { ReactComponent as SmallBlueHome } from "src/assets/svg/icons/small-blue-home.svg";
import { ReactComponent as SmallCirclePlus } from "src/assets/svg/icons/small-circle-plus.svg";
import { ReactComponent as SocialSquareFacebook } from "src/assets/svg/icons/social-square-facebook.svg";
import { ReactComponent as SocialSquareInstagram } from "src/assets/svg/icons/social-square-instagram.svg";
import { ReactComponent as SocialSquareLinkedin } from "src/assets/svg/icons/social-square-linkedin.svg";
import { ReactComponent as SocialSquarePinterest } from "src/assets/svg/icons/social-square-pinterest.svg";
import { ReactComponent as SocialSquareTwitter } from "src/assets/svg/icons/social-square-twitter.svg";
import { ReactComponent as SocialSquareYoutube } from "src/assets/svg/icons/social-square-youtube.svg";
import { ReactComponent as SuccessCircle } from "src/assets/svg/icons/success-circle.svg";
import { ReactComponent as Target } from "src/assets/svg/icons/target.svg";
import { ReactComponent as Touch } from "src/assets/svg/icons/touch.svg";
import { ReactComponent as Telephone } from "src/assets/svg/icons/telephone.svg";
import { ReactComponent as TestCheck } from "src/assets/svg/icons/test-check.svg";
import { ReactComponent as User } from "src/assets/svg/icons/user.svg";
import { ReactComponent as UserManDark } from "src/assets/svg/icons/user-man-dark.svg";
import { ReactComponent as UserManLight } from "src/assets/svg/icons/user-man-light.svg";
import { ReactComponent as UsersGroup } from "src/assets/svg/icons/users-group.svg";
import { ReactComponent as Win } from "src/assets/svg/icons/win.svg";
import { ReactComponent as Empowerment } from "src/assets/svg/icons/empowerment.svg";
import { ReactComponent as Developing } from "src/assets/svg/icons/developing.svg";
import { ReactComponent as Growth } from "src/assets/svg/icons/growth.svg";
import { ReactComponent as ProntoPago } from "src/assets/svg/icons/pronto-pago.svg";
import { ReactComponent as Red } from "src/assets/svg/icons/red.svg";
import { ReactComponent as OurProducts } from "src/assets/svg/icons/ourProducts.svg";
import { ReactComponent as WereIn } from "src/assets/svg/icons/wereIn.svg";
import { ReactComponent as Factura } from "src/assets/svg/icons/factura.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import BoltIcon from '@mui/icons-material/Bolt';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PaymentsIcon from '@mui/icons-material/Payments';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import GroupIcon from '@mui/icons-material/Group';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArticleIcon from '@mui/icons-material/Article';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import TollIcon from '@mui/icons-material/Toll';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GradeIcon from '@mui/icons-material/Grade';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import GroupsIcon from '@mui/icons-material/Groups';
import RestoreIcon from '@mui/icons-material/Restore';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MailIcon from '@mui/icons-material/Mail';
import TimelineIcon from '@mui/icons-material/Timeline';
import FemaleIcon from '@mui/icons-material/Female';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import StoreIcon from '@mui/icons-material/Store';

const components = {
 twoCoin: TwoCoin,
 fourCoin: FourCoin,
 accept: Accept,
 arrowDown: ArrowDown,
 arrowUp: ArrowUp,
 arrow: Arrow,
 buy: Buy,
 calendar: Calendar,
 cancel: Cancel,
 cartLight: CartLight,
 cash: Cash,
 checkDark: CheckDark,
 checkFill: CheckFill,
 checkGray: CheckGray,
 curveDown: CurveDown,
 checkLight: CheckLight,
 close: Close,
 cross: Cross,
 cumploLogo: CumploLogo,
 deleteIcon: DeleteIcon,
 documentCheck: DocumentCheck,
 documentDark: DocumentDark,
 downloadLight: DownloadLight,
 editIcon: EditIcon,
 enterprise: Enterprise,
 filterWhite: FilterWhite,
 filter: Filter,
 geo: Geo,
 giveMoney: GiveMoney,
 goUp: GoUp,
 goDown: GoDown,
 idea: Idea,
 info: Info,
 tech: Tech,
 like: Like,
 lock: Lock,
 magnifyingGlass: MagnifyingGlass,
 mail: Mail,
 menuIcon: MenuIcon,
 message: Message,
 moneyBag: MoneyBag,
 moneyDelete: MoneyDelete,
 moneyGroup: MoneyGroup,
 moneySuccess: MoneySuccess,
 offDark: OffDark,
 phone: Phone,
 presentationStand: PresentationStand,
 pieChartDark: PieChartDark,
 pig: Pig,
 pieChart: PieChart,
 refund: Refund,
 returnPercent: ReturnPercent,
 shake: Shake,
 smallBlueHome: SmallBlueHome,
 smallCirclePlus: SmallCirclePlus,
 socialSquareFacebook: SocialSquareFacebook,
 socialSquareInstagram: SocialSquareInstagram,
 socialSquareLinkedin: SocialSquareLinkedin,
 socialSquarePinterest: SocialSquarePinterest,
 socialSquareTwitter: SocialSquareTwitter,
 socialSquareYoutube: SocialSquareYoutube,
 successCircle: SuccessCircle,
 touch: Touch,
 target: Target,
 telephone: Telephone,
 testCheck: TestCheck,
 user: User,
 userManDark: UserManDark,
 userManLight: UserManLight,
 usersGroup: UsersGroup,
 visibility: Visibility,
 visibilityOff: VisibilityOff,
 win: Win,
 empowerment: Empowerment,
 developing: Developing,
 growth: Growth,
 prontoPago: ProntoPago,
 accountCircleIcon: AccountCircleIcon,
 accountCircleOutlinedIcon: AccountCircleOutlinedIcon,
 bolt: BoltIcon,
 emojiEmotions: EmojiEmotionsIcon,
 phoneAndroid: PhoneAndroidIcon,
 noteAdd: NoteAddIcon,
 payments: PaymentsIcon,
 watchLater: WatchLaterIcon,
 touchApp: TouchAppIcon,
 addReaction: AddReactionIcon,
 accessTimeFilledIcon: AccessTimeFilledIcon,
 groupIcon: GroupIcon,
 accessibilityIcon: AccessibilityIcon,
 tipsAndUpdatesIcon: TipsAndUpdatesIcon,
 monetizationOnIcon: MonetizationOnIcon,
 articleIcon: ArticleIcon,
 paidOutlinedIcon: PaidOutlinedIcon,
 percentIcon: PercentIcon,
 tollIcon: TollIcon,
 volunteerActivismIcon: VolunteerActivismIcon,
 favoriteIcon: FavoriteIcon,
 removeRedEyeIcon: RemoveRedEyeIcon,
 lightbulbIcon: LightbulbIcon,
 gradeIcon: GradeIcon,
 redIcon: Red,
 playArrowIcon: PlayArrowIcon,
 wereIn: WereIn,
 ourProducts: OurProducts,
 accountBalanceIcon: AccountBalanceIcon,
 locationCityIcon: LocationCityIcon,
 emojiPeopleIcon: EmojiPeopleIcon,
 groupsIcon: GroupsIcon,
 restoreIcon: RestoreIcon,
 pointOfSaleIcon: PointOfSaleIcon,
 calendarTodayIcon: CalendarTodayIcon,
 factura: Factura,
 mailIcon: MailIcon,
 storeIcon: StoreIcon,
 femaleIcon: FemaleIcon,
 timelineIcon: TimelineIcon,
 pieChartOutlineIcon: PieChartOutlineIcon

};

const Icon = ({ name, height, width, color, className }) => {
 const SvgIcon = components[name];
 return (
  <SvgIcon
   style={{
    height: height,
    width: width,
    fill: color
   }}
   className={className}
  />
 );
};

Icon.propTypes = {
 name: PropTypes.oneOf(Object.keys(components)),
 width: PropTypes.string,
 height: PropTypes.string,
 className: PropTypes.string,
};

Icon.defaultProps = {
 name: "arrow",
 height: "auto",
 width: "auto",
 className: "",
};

export { Icon };
