import { graphql, useStaticQuery } from 'gatsby'

const GetHeroInvestmentsBannerPE = () => {

 const { allContentfulBanner } = useStaticQuery(graphql`
 {
    allContentfulBanner(
      filter: {name: {eq: "Hero - Inversiones"}, node_locale: {eq: "es-PE"}}
    ) {
      nodes {
        title
        body {
          internal {
            content
          }
        }
        callToAction {
          link
          text
        }
        backgroundDesktop {
          file {
            url
          }
        }
        backgroundMobile {
          file {
            url
          }
        }
      }
    }
  }
        `)
 const results = allContentfulBanner?.nodes[0]
   
 return results
   
}
   
export default GetHeroInvestmentsBannerPE
   
   