import React from "react";
import { Acordeon, ContenidoTextoEnriquecido } from "src/templates";

const PreguntaAcordeon = ({ titulo, contenidos, AcordeonProps }) => {
 return (
  <Acordeon titulo={titulo} {...AcordeonProps}>
   <ContenidoTextoEnriquecido contenidos={contenidos} />
  </Acordeon>
 );
};

export { PreguntaAcordeon };
export default PreguntaAcordeon;
