import React from "react"
import Box from '@mui/material/Box';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from "@mui/material";

const Testimonial = ({avatar, author, position, quote, testimonial, variant = "1"}) => {

 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))

 const styles = {
  testimonialContainer: {
   margin: md ? '0 auto' : 'auto',
   marginLeft: md ? 'auto' : 3,
   marginBottom: 4,
   boxShadow: `0 32px 20px -20px #707c8b3b`,
   width: md ? 'calc(100% - 48px)' : 437,
  },
  testimonial: {
   display: "flex",
   flexDirection: "column",
   backgroundColor: variant === "2" ? theme.palette.text.secondary : `${theme.palette.primary.main}20`,
   minHeight: md ? 420 : 360,
   padding: "32px",
   borderRadius: "20px",
  },
  testimonialHeader: {
   display: 'flex',
   flexDirection: !md ? "row" : "column",
   alignItems: !md ? "auto" : "center",
   justifyContent: 'center',
   alignContent: 'center',
   columnGap: 2,
   paddingBottom: 2,
  },
  testimonialAvatar: {
   height: 80,
   width: 80,
   borderRadius: '100%',
   marginBottom: !md ? "auto" : "20px",
  },
  testimonialAuthor: {
   width: !md ? 330 : 'auto',
  },
  testimonialQuote: {
   paddingBottom: 2,
   borderBottom: `1px solid ${theme.palette.primary.main}`,
   marginBottom: 2,
  },
 }
    
 return (
  <Box item sx={styles.testimonialContainer}>
   <Box sx={styles.testimonial}>
    <Box sx={styles.testimonialHeader}>
     <Box>
      <Box component="img" src={avatar} sx={styles.testimonialAvatar} />
     </Box>
     <Box sx={styles.testimonialAuthor}>
      <Box sx={styles.testimonialAuthorName}>
       <Typography variant="h6" color="primary.dark" align={md ? 'center' : 'left'}>{author},</Typography>
      </Box>
      <Box sx={styles.testimonialAuthorPosition}>
       <Typography variant="body1" color="primary.dark" align={md ? 'center' : 'left'}>{position}</Typography>
      </Box>
     </Box>
    </Box>
    {quote && (
     <Box sx={styles.testimonialQuote}><Typography variant="subtitle2" color="primary.dark" align="center">"{quote}"</Typography></Box>
    )}
    <Box><Typography align="center">{testimonial}</Typography></Box>
   </Box>
  </Box>
 )
}

export { Testimonial }