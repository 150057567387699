import React from "react";
import { CategoriaPregunta, PreguntaAcordeon } from "src/templates";

const FAQ = ({ titulo, descripcion, icono, preguntas = false }) => {
 return (
  <>
   {titulo && (
    <CategoriaPregunta
     titulo={titulo}
     descripcion={descripcion}
     icono={icono}
     cabecera={preguntas ? true : false}
    />
   )}
   {(preguntas || []).map((pregunta, index) => (
    <PreguntaAcordeon {...pregunta} key={index}/>
   ))}
  </>
 );
};

export { FAQ };
export default FAQ;
