const palette = {
 palette: {
  primary: {
   light: "#3A737B",
   main: "#00474f",
   dark: "#012C31",
  },
  secondary: {
   light: "#6AFFDD",
   main: "#02F5AC",
   dark: "#00C07C",
  },
  error: {
   light: "#ff5674",
   main: "#D11149",
   dark: "#990022",
  },
  warning: {
   light: "#FFF25F",
   main: "#F9C028",
   dark: "#C29000",
  },
  info: {
   light: "#FAFAFA",
   main: "#707C8B",
   dark: "#44505e",
  },
  success: {
   light: "#cff6db",
   main: "#9EC3A9",
   dark: "#6f927a",
  },
  text: {
   primary: "#312E38",
   secondary: "#FFFFFF",
   disabled: "#777283"
  }
 },
};

export default palette;