import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from "src/templates"

const HeroInvestments = ({title, body, callToAction, backgroundDesktop, backgroundMobile}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('lg'))
 const sm = useMediaQuery(theme.breakpoints.down('md'))

 const styles = {
  backgroundMediaSmall: {
   marginTop: 0,
   marginBottom: "40px",
   objectFit: "cover",
   width: "100%",
   minHeight: 302,
  },
  backgroundContentSmallContainer:  {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   backgroundImage: `url(${backgroundDesktop})`,
   backgroundRepeat: 'no-repeat',
   backgroundSize: "cover",
   backgroundPosition: 'center',
  
  },
  backgroundContentLargeContainer:  {
   backgroundImage: `url(${backgroundMobile})`,
   backgroundRepeat: 'no-repeat',
   backgroundSize: "cover",
   backgroundPosition: 'center',
   padding: 4, 
  },
  backgroundContentSmall: {
   width: "100%",
   display: 'flex',
   justifyContent: "flex-end",
   alignItems: "center",
   padding: "0px 6.25%",
   maxWidth: "1920px",
   height: 302,
   minHeight: 302,
  }
 }
 
 return (
  <>
   {!md ? (
    <Box sx={styles.backgroundContentSmallContainer}>
     <Box sx={styles.backgroundContentSmall}>
      <Box sx={{width: '60%'}}>
       <Typography variant="h1" color="primary.main">{title}</Typography>
       <Typography variant="subtitle1" color="primary.dark" sx={{mt: 4, mb: 4}}>{body}</Typography>
       {callToAction && (
        <a href={callToAction[0]?.link} style={{ textDecoration: 'none'}}>
         <Button variant={"contained"}>{callToAction[0]?.text}</Button>
        </a>
       )}
      </Box>
     </Box>
    </Box>
   ) : (
    <Box sx={styles.backgroundContentLargeContainer}>
     <Box sx={{width: '100%', textAlign: md ? "center" : "right",   marginRight: md ? 0 : "5%"}}>
      <Typography variant="h1" color={md ? "primary.main" : "primary.main"} fontSize={sm ? "24px !important" : md ? "32px" : "42px"}>{title}</Typography>
     </Box>
     <Box sx={{width: '100%', textAlign: md ? "center" : "right", marginTop: 4, marginRight: md ? 0 : "5%"}}>
      <Typography variant="body1" color={md ? "primary.dark" : "primary.main"} >{body}</Typography>
     </Box>
    </Box>

   )}
  </>
 )
}

export { HeroInvestments }