import { useState } from "react";
import axios from "axios";
import { coerceStringProperty } from "../utils/coercion";
import { cookie } from "cumplo_utils";
import { addfixedHeaders } from "../utils/addFixedHeaders";

const host = process.env.GATSBY_HOST_URL;

export const getUserMePromise = () => {
    const { value: jwt } = cookie.getCookie("cumploSession");
    const config = {
        headers: { Authorization: `Bearer ${jwt}`, ...addfixedHeaders() },
    };
    const url = `${host}/users/me?populate=*`;
    return axios.get(url, config);
};

export const useGetUserMe = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const getUserMe = () => {
        setLoading(true);
        setError("");
        getUserMePromise()
            .then(({ data }) => {
                setResponse(data);
            })
            .catch((err) => {
                console.log(err);
                const textError = coerceStringProperty(
                    err?.response?.data?.error?.message,
                    "Error de servidor"
                );
                setError(textError);
                setResponse(null);
            })
            .finally(() => setLoading(false));
    };
    return { response, loading, error, getUserMe };
};
