import { graphql, useStaticQuery } from 'gatsby'

const GetPurposeContentAreaMX = () => {

 const { allContentfulContentArea } = useStaticQuery(graphql`
 {
    allContentfulContentArea (
  filter: {name: {eq: "Nuestro propósito"}, node_locale: {eq: "es-MX"}}
    ) {
      nodes {
        title
        body {
          internal {
            content
          }
        }
        icons {
          icon
          title
        }
        images {
          file {
            url
          }
        }
        callToAction {
          text
          link
        }
      }
    }
  }
        `)
 const results = allContentfulContentArea?.nodes[0]
   
 return results
   
}
   
export default GetPurposeContentAreaMX
