const breakpoints = {
 breakpoints: {
  values: {
   xs: 0,
   sm: 375,
   md: 834,
   lg: 1024,
   xl: 1440,
   xxl: 1921,
  },
 },
};

export default breakpoints;