/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Helmet from "react-helmet";
import favicon from "src/assets/images/favicon.png";

const Seo = () => {
    let lang = process.env.GATSBY_REACT_APP_REGION;
    let gtagsId = "";
    let hotjarId = "";
    let facebookId = "";
    let facebookDomainVerification = "";
    let selfUrl = "";
    switch (process.env.GATSBY_REACT_APP_REGION) {
        case "es-CL":
            gtagsId = "GTM-5KPKQKB";
            hotjarId = "2569711";
            facebookId = "461699678112459";
            facebookDomainVerification = "ub0b0d2jujdamgow1s2ncv4t4uukub";
            selfUrl = "https://cumplo.cl";
            break;
        case "es-MX":
            gtagsId = "G-KP5NW575TK";
            hotjarId = "2569709";
            facebookId = "";
            facebookDomainVerification = "r8pfmazse9c7pubhbcrfudsnuh634y";
            selfUrl = "https://cumplo.mx";
            break;
        case "es-PE":
            gtagsId = "GTM-WXMPJ6R";
            hotjarId = "3092771";
            facebookId = "992863991874026";
            facebookDomainVerification = "kaxxji2tnhi4e0bz6odie4ky9u4ckx";
            selfUrl = "https://cumplo.pe";
            break;
        default:
            gtagsId = "GTM-MNC62SF9";
            hotjarId = "2569711";
            facebookId = "461699678112459";
            facebookDomainVerification = "ub0b0d2jujdamgow1s2ncv4t4uukub";
    }
    const title = "Cumplo";
    const image = "https://assetscumplo.s3.amazonaws.com/logos/Cumplo_opengraph-image.png";
    const metaAuthor = "Cumplo";
    const metaDescription = "Financiamiento para tu negocio a tasa justa";
    const metaKeywords =
        "financiamiento, inversión, pagadores, empresas, cumplo, negocio, pronto pago, pyme";
    const meta = [];

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`${title}`}
            meta={[
                {
                    name: "description",
                    content: metaDescription,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:image",
                    content: image,
                },
                {
                    property: "og:site_name",
                    content: "Cumplo",
                },
                {
                    name: "keywords",
                    content: metaKeywords,
                },
                {
                    property: "og:description",
                    content: metaDescription,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:url",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary",
                },
                {
                    name: "twitter:creator",
                    content: metaAuthor,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "twitter:description",
                    content: metaDescription,
                },
                {
                    name: "twitter:image",
                    content: image,
                },
                {
                    name: "facebook-domain-verification",
                    content: facebookDomainVerification,
                },
            ].concat(meta)}
        >
            {/* <!-- Google Tag Manager --> */}
            <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtagsId}');`}
            </script>
            {/* <!-- End Google Tag Manager --> */}

            {/* <!-- Start of HubSpot Embed Code --> */}
            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js.hs-scripts.com/8519019.js"
            />
            {/* <!-- End of HubSpot Embed Code --> */}

            {/* START Facebook pixel script */}
            <script>
                {`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${facebookId}');
    fbq('track', 'PageView');`}
            </script>
            <noscript>
                {`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=${facebookId}&ev=PageView&noscript=1"
/>`}
            </noscript>
            {/* END Facebook pixel script */}

            {/* <!-- Hotjar Tracking Code for https://cumplo.cl --> */}
            <script>
                {`(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${hotjarId},hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
            </script>
            {/* END Hotjar script */}
            <link rel="icon" href={favicon} />
        </Helmet>
    );
};

export default Seo;
