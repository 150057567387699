import React from "react";
import domainsJson from "src/config/domainsEnv.json";
import { domain } from "cumplo_utils";
import { TopLayout } from "src/components/common/TopLayout";
import Seo from "./src/components/common/Seo";
import { ApolloProvider } from "@apollo/client";
import { client } from "./src/utils/ApolloClient";
import { SimulatorProvider } from "./src/components/Simulator/SimulatorContext";
domain.setUp(domainsJson);

export const wrapRootElement = ({ element }) => {
  if (window.location.host === "beta.cumplo.pe") {
    window.location.href = "https://cumplo.pe";
  }
  if (window.location.host === "beta.cumplo.cl") {
    window.location.href = "https://cumplo.cl";
  }

  return (
    <SimulatorProvider>
      <ApolloProvider client={client}>
        <TopLayout>
          <Seo />
          {element}
        </TopLayout>
      </ApolloProvider>
    </SimulatorProvider>
  );
};
