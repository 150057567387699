import { graphql, useStaticQuery } from 'gatsby'

const GetHowToContentAreaCO = () => {

 const { allContentfulContentArea } = useStaticQuery(graphql`
 {
    allContentfulContentArea (
  filter: {name: {eq: "¿Cómo lo hacemos?"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        title
        icons {
            icon
            title
            description
        }
        images {
          file {
            url
          }
        }
      }
    }
  }
        `)
 const results = allContentfulContentArea?.nodes[0]
   
 return results
   
}
   
export default GetHowToContentAreaCO
