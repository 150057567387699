import React from 'react'
import { Button, Menu, Typography, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import FlagCl from 'src/assets/images/flag-cl.png'
import FlagMx from 'src/assets/images/flag-mx.png'
import FlagPe from 'src/assets/images/flag-pe.png'

const ExternalLink = ({ url, icon, children, isButton, theme }) => {
 if (!url) {
  return (
   <Box sx={{ background: !isButton ? theme.palette.secondary.light : "transparent", display: "flex", alignItems:"center", justifyContent:"flex-start", padding: !isButton ? "8px" : null}}>
    <img 
     alt="country-icon"
     src={icon}
     style={{ margin: "0px 8px", borderRadius: 4 }}
     width="32px"
     height="22px"
    />
    <Typography style={{ color: theme.palette.text.secondary, display: "flex", alignItems:"center", textTransform: "uppercase", fontWeight: 700}}>
     {children}
    </Typography>
   </Box>
  )
 }
 return (
  <a href={`https://${url}`} style={{textDecoration: "none"}}>
   <Box sx={{ display: "flex", alignItems:"center", justifyContent:"flex-start", padding: !isButton ? "8px" : null}}>
    <img 
     alt="country-icon"
     src={icon}
     style={{ margin: "0px 8px", borderRadius: 4 }}
     width="32px"
     height="22px"
    />
    <Typography color="primary" sx={{ cursor: 'pointer', color: theme.palette.text.primary, textDecoration: "unset", display: "flex", alignItems:"center", textTransform: "uppercase"}} >
     {children}
    </Typography>
   </Box>
  </a>
 )
}

const CountrySelector = ({

 anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
 },
 transformOrigin = {
  vertical: 'top',
  horizontal: 'center',
 },
 noBorder = false,
}) => {
 const theme = useTheme();
 const [anchorEl, setAnchorEl] = React.useState(null)
 const url = 'cumplo.'

 const handleClick = (event) => {
  setAnchorEl(event.currentTarget)
 }
 const handleClose = () => {
  setAnchorEl(null)
 }
 const open = Boolean(anchorEl)
 return (
  <>
   <Button
    style={{ padding: "6px", border: !noBorder ? "2px solid " + theme.palette.secondary.main : "2px solid " + theme.palette.secondary.main, borderRadius: 8 }}
    onClick={handleClick}
   >
    <ExternalLink
     isButton
     locale={process.env.GATSBY_REACT_APP_REGION}
     icon={{
      'es-CL': FlagCl, 'es-MX': FlagMx, 'es-PE': FlagPe,
     }[process.env.GATSBY_REACT_APP_REGION]}
     theme={theme}
    >
     {open ? (
      <KeyboardArrowUp sx={{color: noBorder ? "text.primary" : theme.palette.secondary.main, width: 20, height: 20, left: "-4px", position: "relative"}}/>
     ) : (
      <KeyboardArrowDown sx={{color: noBorder ? "text.primary" : theme.palette.secondary.main, width: 20, height: 20, left: "-4px", position: "relative"}}/>
     )}
    </ExternalLink>
   </Button>
   <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    sx={{ padding: "0px !important"}}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
   >
    {([
     {
      locale: 'es-CL',
      url: process.env.GATSBY_REACT_APP_REGION !== 'es-CL' && `${url}cl`,
      icon: FlagCl,
      text: 'CL',
     },
     {
      locale: 'es-MX',
      url: process.env.GATSBY_REACT_APP_REGION !== 'es-MX' && `${url}mx`,
      icon: FlagMx,
      text: 'MX',
     },
     {
      locale: 'es-PE',
      url: process.env.GATSBY_REACT_APP_REGION !== 'es-PE' && `${url}pe`,
      icon: FlagPe,
      text: 'PE',
     },
    ]).map((country) => <ExternalLink key={country.text} url={country.url} locale={country.locale} icon={country.icon} theme={theme}>{country.text}</ExternalLink>)}
   </Menu>
  </>
 )
}

export { CountrySelector }