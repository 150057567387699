const palette = {
 palette: {
  primary: {
   light: "#74FF95",
   main: "#33D165",
   dark: "#0F7057",
  },
  secondary: {
   light: "#C469FF",
   main: "#8F37D8",
   dark: "#5B00A5",
  },
  error: {
   light: "#F05250",
   main: "#B71527",
   dark: "#7F0000",
  },
  warning: {
   light: "#EF9B41",
   main: "#E96B06",
   dark: "#B03C00",
  },
  info: {
   light: "#6990FF",
   main: "#1563E8",
   dark: "#003AB5",
  },
  success: {
   light: "#3F8F77",
   main: "#00614B",
   dark: "#003623",
  },
  text: {
   primary: "#312E38",
   secondary: "#FFFFFF",
   disabled: "#777283"
  }
 },
};
   
export default palette;