
import React from "react"
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Arrow from "src/assets/svg/title-arrow.svg";

const Title = ({text, arrow = false, contrast = false}) => {
 
 return (
  <Box sx={{display: "flex", justifyContent: "center", alignItems: "flex-start", columnGap: 2}}>
   {arrow && <Box component="img" src={Arrow} display="inline-block" width="40px"/>}
   <Box display={"inline-block"}>
    <Typography display="inline" variant="h3" color={ contrast ? "text.secondary" : "secondary.main"}>{text.split('/')[0]}</Typography>
    <Typography sx={{ fontStyle: text.split('/')[2] ? "italic" : "auto"}} display="inline" variant="h3" color={ contrast ? "text.secondary" : "primary.main"}>{text.split('/')[1]}</Typography>
    {text.split('/')[2] && (
     <Typography display="inline" variant="h3" color={ contrast ? "text.secondary" : "primary.main"}>{text.split('/')[2]}</Typography>
    )}
   </Box>
  </Box>
 )}

export { Title }