import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as LogoFull } from "src/assets/svg/logos/logo-full-new.svg";
import { ReactComponent as LogoIsotype } from "src/assets/svg/logos/logo-isotype-new.svg";

const variants = {
 FULL: "full",
 ISOTYPE: "isotype",
};

const sizes = {
 DEFAULT: "20px",
 MEDIUM: "40px",
 LARGE: "150px",
};

const colors = {
 PRIMARY: "primary",
 WHITE: "white",
};

const Logo = ({ variant, size, color }) => {
 const theme = useTheme();
 const fillColor = color === colors.WHITE ? theme.palette.text.secondary : theme.palette.primary.main;
 return variant === variants.FULL ? (
  <LogoFull
   fill={color}
   style={{width: size}}
  />
 ) : (
  <LogoIsotype
   fill={fillColor}
   style={{width: size}}
  />
 );
};

Logo.propTypes = {
 variant: PropTypes.oneOf(Object.values(variants)),
 height: PropTypes.oneOf(Object.values(sizes)),
 color: PropTypes.string,
};

Logo.defaultProps = {
 variant: variants.FULL,
 height: sizes.DEFAULT,
 color: colors.PRIMARY,
};

export { Logo };
