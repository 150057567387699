import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const styles = {
 container:(mq)=>{
  return{
   width: '100%',
   flexDirection:'column',
   justifyContent:'center',
   height:'auto',
   background: 'rgba(68,80,94,0)',
   boxShadow:'0',
   backgroundSize: 'cover',
  }
 },
 itemInput: {
  backgroundColor: 'white',
  justifyContent: 'space-between',
  alignItems:'center',
  textAlign:'right',
  borderRadius: '10px',
  margin:'21px',
  height:'60px'
 },
 itemLabel: {
  display:'flex',
  alignItems:'center',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255,255,255,0)',
  margin:'7px 21px',
  color:'#fff',
  fontWeight: 'bold',
  border: '1px',
  borderBottomStyle:'solid',
  padding: '10px',
 },
 itemText:(mq)=>{
  return{
   lineHeight:  mq ? '25px' : '18px',
   maxWidth: mq ? '100%' : '130px',
   position:'absolute',
   zIndex:'100',
   padding: mq ? '17px' : '13px',
   height:'60px',
  }
 },
 itemFooter:{
  display:'flex',
  justifyContent:'center',
  flexDirection:'column',
  alignItems:'center',
  backgroundColor: 'rgba(255,255,255,0)',
  textAlign: "center",
  marginTop: '21px',
  color:'#fff',
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.004em',
  padding: '10px',
 },
 customSelect:{width:'100%',marginTop:'5px',marginRight:'3px',},
 customSelectPaper:{
  style: {
   height:'300px',
   scrollbarWidth: 'none',
  },
  sx:{
   '&::-webkit-scrollbar':{display:'none'},
  }
 }
}

const CustomSelectInput = styled(InputBase)(({ theme }) => ({
 'label + &': {
  marginTop: theme.spacing(3),
 },
 '& .MuiInputBase-input': {
  width:'100%',
  borderRadius: 4,
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: '0px solid #ced4da',
  fontSize: '19',
  fontWeight: 'bold',
  lineHeight: '30px',
  padding: '10px 26px 10px 12px',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  fontFamily: [
   '-apple-system',
   'BlinkMacSystemFont',
   '"Segoe UI"',
   'Roboto',
   '"Helvetica Neue"',
   'Arial',
   'sans-serif',
   '"Apple Color Emoji"',
   '"Segoe UI Emoji"',
   '"Segoe UI Symbol"',
  ].join(','),
 }
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
 'label + &': {
  marginTop: theme.spacing(3),
 },
 '& .MuiInputBase-input': {
  //display:'flex',
  width:'100%',
  align:'right',
  alignItems: 'center',
  justifyContent:'right',
  fontWeight: 'bold',
  padding: '10px 26px 10px 12px',
  marginTop:'5px',
  marginRight:'3px',
  backgroundColor: theme.palette.background.paper,
  border: '0px solid #ced4da',
  fontSize: '19',
  lineHeight: '30px',
  textAlign:'right',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  fontFamily: [
   '-apple-system',
   'BlinkMacSystemFont',
   '"Segoe UI"',
   'Roboto',
   '"Helvetica Neue"',
   'Arial',
   'sans-serif',
   '"Apple Color Emoji"',
   '"Segoe UI Emoji"',
   '"Segoe UI Symbol"',
  ].join(','),
  '&:focus': {
   borderRadius: 4,
   borderColor: '#80bdff',
  },
 },
}));
export {styles,CustomSelectInput, CustomInput}
