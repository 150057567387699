import React, { Fragment } from "react";
import { Box, Typography, useTheme } from "@mui/material";

const TIPOS = {
 TEXTO: "TEXTO",
 IMAGEN: "IMAGEN",
};

const ContenidoTextoEnriquecido = (props) => {
 const { contenidos } = props;

 const RenderizarPosibleEnlace = ({ contenido, children }) => {
  const theme = useTheme();
  if (!contenido.enlace && !contenido.mailto && !contenido.tel)
   return <Fragment>{children}</Fragment>;

  if (contenido.enlace) {
   return (
    <a
     style={{ color: theme.palette.secondary.main, textDecoration: "none" }}
     href={contenido.enlace}
     target="_blank"
     rel="noopener noreferrer"
    >
     {children}
    </a>
   );
  }

  if (contenido.mailto) {
   return (
    <a
     style={{ color: theme.palette.secondary.main, textDecoration: "none" }}
     href={"mailto:" + contenido.mailto}
     rel="noopener noreferrer"
    >
     {children}
    </a>
   );
  }
  if (contenido.tel) {
   return (
    <a
     style={{ color: theme.palette.secondary.main, textDecoration: "none" }}
     href={"tel:" + contenido.tel}
     rel="noopener noreferrer"
    >
     {children}
    </a>
   );
  }
 };

 const renderizarTexto = (contenido) => {
  return (
   <Fragment key={contenido.texto}>
    <Typography
     sx={{
      display: "inline",
      ...(contenido.enNegritas ? { fontWeight: 800 } : {}),
      ...(contenido.enCursivas ? { fontStyle: "italic" } : {}),
      ...(contenido.enlace || contenido.mailto || contenido.tel
       ? { fontWeight: 700}
       : {}),
     }}
     variant={contenido.variante}
    >
     {contenido.texto}
    </Typography>
    {contenido.finParrafo ? <br /> : undefined}
   </Fragment>
  );
 };

 const renderizarImagen = (contenido) => {
  return (
   <Box
    component="img"
    sx={{
     margin: "0 auto",
     display: "block",
     maxWidth: "100%",
     content: {
      xs: `url(${contenido.src?.xs})`,
      ...(contenido?.src?.md ? { md: `url(${contenido?.src?.md})` } : {}),
      ...(contenido?.src?.lg ? { lg: `url(${contenido?.src?.lg})` } : {}),
      ...(contenido?.src?.xl ? { xl: `url(${contenido?.src?.xl})` } : {}),
     },
    }}
    title={contenido.titulo}
   />
  );
 };

 return (
  <Fragment>
   {(contenidos || []).map((contenido, index) => (
    <RenderizarPosibleEnlace contenido={contenido} key={index}>
     {contenido.tipo === TIPOS.TEXTO ? renderizarTexto(contenido) : null}
     {contenido.tipo === TIPOS.IMAGEN
      ? renderizarImagen(contenido?.url)
      : null}
    </RenderizarPosibleEnlace>
   ))}
  </Fragment>
 );
};

export { ContenidoTextoEnriquecido };
export default ContenidoTextoEnriquecido;
