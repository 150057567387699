import React from "react";

import Backdrop from "@mui/material/Backdrop";
import {
  Container,
  Box,
  Card,
  Fade,
  Grid,
  Typography,
  Modal,
  List,
  ListItem,
} from "@mui/material";

import { Logo } from "src/templates";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import { styled } from "@mui/system";

import FintechPeru from "src/assets/svg/logos/enterprises/fintechPeru.svg";
import EmpresaB from "src/assets/svg/logos/enterprises/empresaB.svg";

import Ifit from "src/assets/svg/logos/enterprises/ifit.svg";
import Condusef from "src/assets/images/logos/enterprises/condusef.png";

import terminos from "src/assets/pdf/terminos_y_condiciones_mx.pdf";
import politicas from "src/assets/pdf/politicas_de_privacidad_mx.pdf";
import seguridad from "src/assets/pdf/seguridad_mx.pdf";

import FlagCl from "src/assets/images/flag-cl.png";
import FlagMx from "src/assets/images/flag-mx.png";
import FlagPe from "src/assets/images/flag-pe.png";

import { Icon } from "src/templates";

const Footer = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [open, setOpen] = React.useState(false);
  const [openBuro, setOpenBuro] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenBuro = () => {
    setOpenBuro(true);
  };

  const handleCloseBuro = () => {
    setOpenBuro(false);
  };

  const FooterColumnTitle = styled(Typography)(({ theme }) => ({
    paddingBottom: 32,
    [theme.breakpoints.down("md")]: {
      minHeight: 48,
    },
  }));

  const LinkContact = styled("a")(({ theme }) => ({
    color: theme.palette.text.secondary,
    textDecoration: "none !important",
    display: "inline-block",
  }));

  const LinkCountry = styled("a")(({ theme }) => ({
    color: theme.palette.text.secondary,
    textDecoration: "none !important",
    display: "inline-block",
  }));

  const LinkPages = styled("a")(({ theme }) => ({
    color: theme.palette.text.secondary,
    textDecoration: "none !important",
    display: "inline-block",
    paddingBottom: 16,
  }));

  const CallIconStyled = styled(PhoneAndroidIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 22,
    height: 22,
    marginRight: 10,
  }));

  const WhatsAppIconStyled = styled(WhatsAppIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 22,
    height: 22,
    marginRight: 10,
  }));

  const MailIconStyled = styled(EmailOutlinedIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 22,
    height: 22,
    marginRight: 10,
  }));

  const YouTubeIconStyled = styled(YouTubeIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 26,
    height: 26,
    marginRight: "7.5px",
  }));

  const LinkedInIconStyled = styled(LinkedInIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 26,
    height: 26,
    marginRight: "7.5px",
  }));

  const InstagramIconStyled = styled(InstagramIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 26,
    height: 26,
    marginRight: "7.5px",
  }));

  const FacebookIconStyled = styled(FacebookIcon)(({ theme }) => ({
    color: theme.palette.primary.light,
    width: 26,
    height: 26,
    marginRight: "7.5px",
  }));

  const ListRow = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  }));

  const ListTitle = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.dark,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingBottom: 8,
      textAlign: "center",
    },
  }));

  const ListContent = styled(Box)(({ theme }) => ({
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      textAlign: "center",
    },
  }));

  const Disclaimer = styled(Box)(({ theme }) => ({
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  const countryLinks = [
    ["Cumplo Perú", "https://cumplo.pe", FlagPe],
    ["Cumplo Chile", "https://cumplo.cl", FlagCl],
    ["Cumplo México", "https://cumplo.mx", FlagMx],
  ];

  const financingLinksCL = [
    ["Términos y regulaciones", "/terminos-y-regulaciones"],
    ["Preguntas frecuentes", "/ayuda"],
    ["Trabaja en Cumplo", "https://cumplo.breezy.hr/"],
    ["Canal de denuncias", "https://etica.resguarda.com/cumplo/main_es.html"],
    ["Canal de reclamos", "https://productos.cumplo.cl/es/sac"],
  ];

  const financingLinksMX = [
    ["Términos y condiciones", "/terminos-y-condiciones"],
    ["Políticas de privacidad", "/politicas-de-privacidad"],
    ["Recomendaciones de seguridad", "/recomendaciones-de-seguridad"],
    ["Dudas y reclamaciones", "mailto:contactanos@cumplo.com"],
    ["Trabaja en Cumplo", "https://cumplo.breezy.hr/"],
    ["Noticias", "https://noticias.cumplo.mx/"],
  ];

  const financingLinksPE = [
    ["Términos y regulaciones", "/terminos-y-regulaciones"],
    ["Preguntas frecuentes", "/ayuda"],
    ["Trabaja en Cumplo", "https://cumplo.breezy.hr/"],
  ];

  const socialNetworks = [
    {
      link: "https://www.linkedin.com/company/cumplo/",
      icon: LinkedInIconStyled,
      text: "/cumplo",
    },
    {
      link: "https://www.youtube.com/channel/UCUX1hPS59JLlGAZut2IlN-Q",
      icon: YouTubeIconStyled,
      text: "@cumplo",
    },
    {
      link: "https://www.instagram.com/cumplo/",
      icon: InstagramIconStyled,
      text: "@cumplo",
    },
    {
      link: "https://www.facebook.com/cumplo",
      icon: FacebookIconStyled,
      text: "/cumplo",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "primary.dark",
        color: "text.secondary",
        padding: !lg ? "32px 96px" : "64px 32px 48px",
      }}
    >
      <Container>
        {/* Unidad especializada de atención */}
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card
              sx={{
                borderRadius: "20px",
                padding: "24px 24px 48px",
                maxWidth: "750px",
                zIndex: 0,
                margin: 2,
                border: 0,
                outline: "none",
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  width: "100%",
                  textAlign: "right",
                  paddingRight: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    name="close"
                    color={theme.palette.secondary.main}
                    height="40px"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 1,
                  alignItems: "center",
                }}
              >
                <Logo
                  variant={"full"}
                  size="125px"
                  color={theme.palette.primary.main}
                />
                <Typography
                  variant="h4"
                  align="center"
                  fontWeight="500"
                  color="primary.dark"
                  sx={{
                    width: "416px !important",
                  }}
                >
                  Ekscelenko
                </Typography>
                <Disclaimer>
                  <Typography
                    variant="body2"
                    sx={{ paddingTop: 4, paddingBottom: 6 }}
                  >
                    En cumplimiento con lo establecido por el artículo 50 Bis de
                    la Ley de Protección y Defensa de los Usuarios de Servicios
                    Financieros; EKSCELENKO S.A.P.I DE C.V SOFOM E.N.R., informa
                    al público usuario los datos de identificación de su Unidad
                    Especializada.
                  </Typography>
                </Disclaimer>
              </Box>
              {/* Lista dentro del modal de unidad de atención a usuarios */}
              <ListRow>
                <ListTitle>
                  <Typography variant="subtitle2">
                    Titular y Encargada Regional de la UNE:
                  </Typography>
                </ListTitle>
                <ListContent>MONTSERRAT MARTÍNEZ CHÁVEZ</ListContent>
              </ListRow>
              <Box
                sx={{
                  borderTop: "1px solid " + theme.palette.primary.main,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <ListRow>
                <ListTitle>
                  <Typography variant="subtitle2">
                    Ubicación del Titular y Encargado de la UNE:
                  </Typography>
                </ListTitle>
                <ListContent>
                  Lago Alberto 442, torre A piso 5, oficina 503. Miguel Hidalgo,
                  C.P. 11320. Ciudad de México.
                </ListContent>
              </ListRow>
              <Box
                sx={{
                  borderTop: "1px solid " + theme.palette.primary.main,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <ListRow>
                <ListTitle>
                  <Typography variant="subtitle2">
                    Horario de atención:
                  </Typography>
                </ListTitle>
                <ListContent>
                  Lunes a Jueves de 9:00 a 18:00 Hrs. <br />
                  Viernes de 9:00 a 16:00 Hrs. <br />
                  (En días hábiles) <br />
                </ListContent>
              </ListRow>
              <Box
                sx={{
                  borderTop: "1px solid " + theme.palette.primary.main,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <ListRow>
                <ListTitle>
                  <Typography variant="subtitle2">Teléfonos:</Typography>
                </ListTitle>
                <ListContent>+52 (55) 8079 5477</ListContent>
              </ListRow>
              <Box
                sx={{
                  borderTop: "1px solid " + theme.palette.primary.main,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <ListRow>
                <ListTitle>
                  <Typography variant="subtitle2">
                    Correo electrónico:
                  </Typography>
                </ListTitle>
                <ListContent>
                  <a
                    href="mailto:une@cumplo.com"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    une@cumplo.com
                  </a>
                </ListContent>
              </ListRow>
            </Card>
          </Fade>
        </Modal>

        {/* BURIIFIT */}
        <Modal
          open={openBuro}
          onClose={handleCloseBuro}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openBuro}>
            <Card
              sx={{
                borderRadius: "20px",
                padding: "24px 24px 48px",
                maxWidth: "750px",
                zIndex: 0,
                margin: 2,
                border: 0,
                outline: "none",
                position: md ? "absolute" : "relative",
                top: 0,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  textAlign: "right",
                  paddingRight: 2,
                  paddingLeft: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={Ifit}
                  sx={{ maxWidth: "100%", height: 70 }}
                />
                <div
                  onClick={handleCloseBuro}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    name="close"
                    color={theme.palette.secondary.main}
                    height="40px"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderTop: "1px solid " + theme.palette.primary.main,
                    marginTop: 4,
                    marginBottom: 2,
                  }}
                >
                  <List sx={{ listStyleType: "disc", pl: 4, paddingTop: 3 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <Typography variant="body2">
                        Es una herramienta de consulta y difusión con la que
                        podrás conocer los productos que ofrecen las entidades
                        financieras, sus comisiones y tasas, las reclamaciones
                        de los usuarios, las prácticas no sanas en que incurren,
                        las sanciones administrativas que les han impuesto, las
                        cláusulas abusivas de sus contratos y otra información
                        que resulte relevante para informarte sobre su
                        desempeño.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <Typography variant="body2">
                        Con el Buró de Entidades Financieras, se logrará saber
                        quién es quién en bancos, seguros, sociedades
                        financieras de objeto múltiple, cajas de ahorro, afores,
                        entre otras entidades.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <Typography variant="body2">
                        Con ello, podrás comparar y evaluar a las entidades
                        financieras, sus productos y servicios y tendrás mayores
                        elementos para elegir lo que más te convenga.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <Typography variant="body2">
                        Esta información te será útil para elegir un producto
                        financiero y también para conocer y usar mejor los que
                        ya tienes.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <Typography variant="body2">
                        Este Buró de Entidades Financieras, es una herramienta
                        que puede contribuir al crecimiento económico del país,
                        al promover la competencia entre las instituciones
                        financieras; que impulsará la transparencia al revelar
                        información a los usuarios sobre el desempeño de éstas y
                        los productos que ofrecen y que va a facilitar un manejo
                        responsable de los productos y servicios financieros al
                        conocer a detalle sus características.
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <Typography variant="body2">
                        Lo anterior, podrá derivar en un mayor bienestar social,
                        porque al conjuntar en un solo espacio tan diversa
                        información del sistema financiero, el usuario tendrá
                        más elementos para optimizar su presupuesto, para
                        mejorar sus finanzas personales, para utilizar
                        correctamente los créditos que fortalecerán su economía
                        y obtener los seguros que la protejan, entre otros
                        aspectos.
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <a
                    href={"https://www.buro.gob.mx/"}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      variant="body1"
                      color="secondary.main"
                      sx={{ fontWeight: 700 }}
                    >
                      https://www.buro.gob.mx/
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Card>
          </Fade>
        </Modal>
        <Box
          sx={{
            display: "flex",
            flexDirection: !md ? "row" : "column",
            columnGap: 2,
            rowGap: 1,
            justifyContent: "space-between",
          }}
        >
          <Box>
            {/* Logo de Cumplo */}
            <Box sx={{ minHeight: 62 }}>
              <Logo
                color={theme.palette.primary.light}
                size={!lg ? "160px" : !md ? "150px" : "120px"}
              />
            </Box>
            {/* Dirección */}
            {process.env.GATSBY_REACT_APP_REGION === "en-US" && (
              <Box sx={{ paddingBottom: "16px", minHeight: 42 }}>
                <Typography gutterBottom>
                  17350 State Hwy 249, Ste 220 #1580, <br />
                  Houston, Texas 94105, CumploTex LLC
                </Typography>
              </Box>
            )}
            {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
              <Box sx={{ paddingBottom: "16px", minHeight: 42 }}>
                <Typography gutterBottom>
                  Apoquindo 5400, piso 2. Las Condes
                </Typography>
              </Box>
            )}
            {process.env.GATSBY_REACT_APP_REGION === "es-MX" && (
              <Box sx={{ paddingBottom: "16px", minHeight: 42 }}>
                <Typography gutterBottom>
                  Lago Alberto 442, torre A piso 5, oficina 503. {!md && <br />}{" "}
                  Miguel Hidalgo, C.P. 11320. {!md && <br />} Ciudad de México.
                </Typography>
              </Box>
            )}
            {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
              <Box sx={{ paddingBottom: "16px", minHeight: 42 }}>
                <Typography gutterBottom>
                  Av. José Larco 1232, Miraflores, Lima
                </Typography>
              </Box>
            )}
            {/* Número telefónico */}
            {process.env.GATSBY_REACT_APP_REGION !== "en-US" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "16px",
                  minHeight: 42,
                }}
              >
                <CallIconStyled />
                {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
                  <LinkContact href={`tel:+56222464050`}>
                    <Typography variant="subtitle1" color="text.secondary">
                      (+56 2) 2246 4050
                    </Typography>
                  </LinkContact>
                )}
                {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
                  <LinkContact href={`tel:+51914451815`}>
                    <Typography variant="subtitle1" color="text.secondary">
                      +51 914 451 815
                    </Typography>
                  </LinkContact>
                )}
                {process.env.GATSBY_REACT_APP_REGION === "es-MX" && (
                  <LinkContact href={`tel:+525571002310`}>
                    <Typography variant="subtitle1" color="text.secondary">
                      +52 (55) 7100 2310
                    </Typography>
                  </LinkContact>
                )}
              </Box>
            )}
            {/* Número de WhatsApp */}
            {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "16px",
                  minHeight: 42,
                }}
              >
                <WhatsAppIconStyled />
                <LinkContact href={`https://wa.link/giq0kd`} target="_blank">
                  <Typography variant="subtitle1" color="text.secondary">
                    (+56 9) 3069 9653
                  </Typography>
                </LinkContact>
              </Box>
            )}
            {/* Correo electrónico */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "16px",
                minHeight: 42,
              }}
            >
              <MailIconStyled />
              {process.env.GATSBY_REACT_APP_REGION === "en-US" && (
                <LinkContact href={`mailto:contact@cumplo.com`}>
                  <Typography variant="subtitle2" color="text.secondary">
                    contact@cumplo.com
                  </Typography>
                </LinkContact>
              )}
              {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
                <LinkContact href={`mailto:contacto@cumplo.cl`}>
                  <Typography variant="subtitle2" color="text.secondary">
                    contacto@cumplo.cl
                  </Typography>
                </LinkContact>
              )}
              {process.env.GATSBY_REACT_APP_REGION === "es-MX" && (
                <LinkContact href={`mailto:contacto@cumplo.mx`}>
                  <Typography variant="subtitle2" color="text.secondary">
                    contacto@cumplo.mx
                  </Typography>
                </LinkContact>
              )}
              {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
                <LinkContact href={`mailto:contacto@cumplo.pe`}>
                  <Typography variant="subtitle2" color="text.secondary">
                    contacto@cumplo.pe
                  </Typography>
                </LinkContact>
              )}
            </Box>
          </Box>
          {/* Columna de enlaces, mapsite, links */}
          {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
            <>
              <Box>
                {!md && <Box sx={{ paddingBottom: "58px" }} />}
                <Grid xs={12} item>
                  <Grid container>
                    <Grid item>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/pymes/"}>
                          Productos para pymes
                        </LinkPages>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/corporaciones/"}>
                          Productos para corporaciones
                        </LinkPages>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/oportunidades-de-inversion/"}>
                          Inversiones
                        </LinkPages>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {!md && <Box sx={{ paddingBottom: "58px" }} />}
                <Grid xs={12} item>
                  <Grid container>
                    <Grid item>
                      {financingLinksCL?.slice(0, 6)?.map((item, index) => (
                        <Typography variant="body1" key={index} gutterBottom>
                          <LinkPages
                            href={item[1]}
                            target={
                              item[0] === "Trabaja en Cumplo"
                                ? "_blank"
                                : "false"
                            }
                          >
                            {item[0]}
                          </LinkPages>
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
            <>
              <Box>
                {!md && <Box sx={{ paddingBottom: "58px" }} />}
                <Grid xs={12} item>
                  <Grid container>
                    <Grid item>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/pymes/"}>
                          Productos para pymes
                        </LinkPages>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/corporaciones/"}>
                          Productos para corporaciones
                        </LinkPages>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/inversiones/"}>
                          Inversiones
                        </LinkPages>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {!md && <Box sx={{ paddingBottom: "58px" }} />}
                <Grid xs={12} item>
                  <Grid container>
                    <Grid item>
                      {financingLinksPE?.slice(0, 6)?.map((item, index) => (
                        <Typography variant="body1" key={index} gutterBottom>
                          <LinkPages
                            href={item[1]}
                            target={
                              item[0] === "Trabaja en Cumplo"
                                ? "_blank"
                                : "false"
                            }
                          >
                            {item[0]}
                          </LinkPages>
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {process.env.GATSBY_REACT_APP_REGION === "es-MX" && (
            <>
              <Box>
                {!md && <Box sx={{ paddingBottom: "58px" }} />}
                <Grid xs={12} item>
                  <Grid container>
                    <Grid item>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={terminos}>
                          Términos y condiciones
                        </LinkPages>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={politicas}>
                          Políticas de privacidad
                        </LinkPages>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={seguridad}>
                          Recomendaciones de seguridad
                        </LinkPages>
                      </Typography>
                      {financingLinksMX?.slice(3, 4)?.map((item, index) => (
                        <Typography variant="body1" key={index} gutterBottom>
                          <LinkPages
                            href={item[1]}
                            target={item[0] === "Trabaja en Cumplo" && "_blank"}
                          >
                            {item[0]}
                          </LinkPages>
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {!md && <Box sx={{ paddingBottom: "58px" }} />}
                <Grid xs={12} item>
                  <Grid container>
                    <Grid item>
                      {financingLinksMX?.slice(4, 7)?.map((item, index) => (
                        <Typography variant="body1" key={index} gutterBottom>
                          <LinkPages
                            href={item[1]}
                            target={item[0] === "Trabaja en Cumplo" && "_blank"}
                          >
                            {item[0]}
                          </LinkPages>
                        </Typography>
                      ))}
                      <Typography
                        variant="body1"
                        gutterBottom
                        onClick={handleOpen}
                        sx={{ cursor: "pointer", paddingBottom: 2 }}
                      >
                        Unidad especializada de atención
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <LinkPages href={"/prevencion-de-fraudes"}>
                          Prevención de fraudes
                        </LinkPages>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {/* Última columna */}
          {process.env.GATSBY_REACT_APP_REGION !== "en-US" && (
            <Box>
              {/* Selector de banderas, países */}
              <FooterColumnTitle variant="subtitle1" color="text.secondary">
                Estamos en
              </FooterColumnTitle>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 3,
                  minHeight: 40,
                }}
              >
                {countryLinks?.slice(0, 3)?.map((item, index) => (
                  <LinkCountry href={item[1]} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          background: theme.palette.text.secondary,
                          padding: "4px 0px",
                          borderRadius: "30px",
                          display: "flex",
                          alignItems: "center",
                          marginRight: 1,
                        }}
                      >
                        <img
                          alt="country-icon"
                          src={item[2]}
                          style={{ margin: "0px 8px", borderRadius: 4 }}
                          width="24px"
                          height="16px"
                        />
                      </Box>
                      <Typography variant="body1" key={index}>
                        {item[0]}
                      </Typography>
                    </Box>
                  </LinkCountry>
                ))}
              </Box>
              {/* Redes sociales */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  minHeight: 40,
                  marginTop: 2,
                }}
              >
                {socialNetworks.slice(0, 6)?.map((item, index) => {
                  const Component = item.icon;
                  return (
                    <a
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        marginRight: 5,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      key={index}
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Component />
                    </a>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            columnGap: 2,
            paddingTop: 2,
          }}
        >
          <>
            {process.env.GATSBY_REACT_APP_REGION === "es-PE" ? (
              <Typography
                sx={{
                  borderTop: "2px solid " + theme.palette.primary.light,
                  paddingTop: 2,
                  paddingBottom: md ? 4 : 2,
                }}
                variant="caption"
              >
                “CUMPLO PERU S.A.C. se encuentra inscrita en el Registro de
                Empresas de Factoring No Comprendidas en el Ámbito de la Ley N°
                26702 de acuerdo a la constancia de inscripción emitida por la
                Superintendencia de Banca, Seguros y AFP con fecha 02 de febrero
                2022.”
              </Typography>
            ) : (
              <Box
                sx={{
                  borderTop: "2px solid " + theme.palette.primary.light,
                  paddingTop: 1,
                  paddingBottom: 2,
                }}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: !md ? "row" : "column",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: "24px",
                rowGap: "36px",
              }}
            >
              {/* EmpresaB */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: md ? "100%" : "50%",
                }}
              >
                <Box
                  component="img"
                  src={EmpresaB}
                  sx={{ height: 60, paddingRight: "24px" }}
                />
                <Typography
                  sx={{ maxWidth: md ? "none" : "520px" }}
                  variant="caption"
                >
                  Somos Empresa B. Trabajamos por una economía más justa y
                  medimos nuestro impacto con la comunidad y con el planeta.
                </Typography>
              </Box>
              {/* Fintech */}
              {process.env.GATSBY_REACT_APP_REGION !== "es-CL" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    maxWidth: md ? "100%" : "50%",
                  }}
                >
                  {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
                    <>
                      <Typography sx={{ width: "105px" }} variant="caption">
                        Somos miembros de la asociación
                      </Typography>
                      <Box
                        component="img"
                        src={FintechPeru}
                        sx={{ maxWidth: "100%", marginRight: "-24px" }}
                      />
                    </>
                  )}
                  {process.env.GATSBY_REACT_APP_REGION === "es-MX" && (
                    <>
                      <a
                        href="https://www.condusef.gob.mx/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Box
                          component="img"
                          src={Condusef}
                          sx={{
                            maxWidth: "100%",
                            height: 60,
                            paddingRight: "24px",
                          }}
                        />
                      </a>
                      <Box
                        component="img"
                        src={Ifit}
                        sx={{ maxWidth: "100%", height: 60, cursor: "pointer" }}
                        onClick={handleOpenBuro}
                      />
                    </>
                  )}
                </Box>
              )}
            </Box>
          </>
        </Box>
      </Container>
    </Box>
  );
};

export { Footer };
