// Imports para los contenidos

// Imports para los contenidos de CL

import GetSolutionsSliderCL from './es-CL/solutionsSlider'
import GetHeaderNavigationBarCL from './es-CL/headerNavigationBar'
import GetTestimonialsCarrouselHomeCL from './es-CL/testimonialsCarrouselHome'
import GetTestimonialsCarrouselPymesCL from './es-CL/testimonialsCarrouselPymes'
import GetTestimonialsCarrouselImpactoCL from './es-CL/testimonialsCarrouselImpacto'
import GetBenefitsBenefitsCL from './es-CL/benefitsBenefits'
import GetFeaturesHowToCL from './es-CL/featuresHowTo'
import GetHeroHomeBannerCL from './es-CL/heroHomeBanner'
import GetHeroPymesBannerCL from './es-CL/heroPymesBanner'
import GetHeroNosotrosBannerCL from './es-CL/heroNosotrosBanner'
import GetHeroEnterprisesBannerCL from './es-CL/heroEnterprisesBanner'
import GetHeroInvestmentsBannerCL from './es-CL/heroInvestmentsBanner'
import GetHeroImpactoBannerCL from './es-CL/heroImpactoBanner'
import GetPurposeContentAreaCL from './es-CL/purposeContentArea'
import GetHowToContentAreaCL from './es-CL/howToContentArea'
import GetLiquidityContentAreaCL from './es-CL/liquidityContentArea'
import GetReportContentAreaCL from './es-CL/reportContentArea'
import GetPymesSolutionsFeaturesCL from './es-CL/pymesSolutionsFeatures'
import GetAllyFormContentAreaCL from './es-CL/allyFormContentArea'
import GetBenefitsEnterprisesFeaturesCL from './es-CL/benefitsEnterprisesFeatures'
import GetProntoPagoFeaturesCL from './es-CL/prontoPagoFeatures'
import GetHowToEnterprisesFeaturesCL from './es-CL/howToEnterprisesFeatures'
import GetWhatMakesUsContentAreaCL from './es-CL/whatMakesUsContentArea'
import GetNosotrosFeaturesCL from './es-CL/nosotrosFeatures'
import GetTimelineFeaturesCL from './es-CL/timelineFeatures'
import GetProductCardsFeaturesCL from './es-CL/productCardsFeatures'
import GetFAQSobreCumploCL from './es-CL/faqSobreCumplo'
import GetFAQInversionesCL from './es-CL/faqInversiones'
import GetFAQSaldoCumploCL from './es-CL/faqSaldoCumplo'
import GetFAQAnticiposCL from './es-CL/faqAnticipos'
import GetStatsImpactoCL from './es-CL/statsImpacto'
import GetFeaturesImpactoCL from './es-CL/whatIsImpactoFeatures'
import GetImpactoCommitmentsFeaturesCL from './es-CL/impactoCommitmentsFeatures'
import GetTermsConditionsCL from './es-CL/termsConditions'
import GetPrivacyPolicyCL from './es-CL/privacyPolicy'
import GetCookiesPolicyCL from './es-CL/cookiesPolicy'

// Imports para los contenidos de US

import GetSolutionsSliderUS from './en-US/solutionsSlider'
import GetHeaderNavigationBarUS from './en-US/headerNavigationBar'
import GetTestimonialsCarrouselHomeUS from './en-US/testimonialsCarrouselHome'
import GetTestimonialsCarrouselPymesUS from './en-US/testimonialsCarrouselPymes'
import GetTestimonialsCarrouselImpactoUS from './en-US/testimonialsCarrouselImpacto'
import GetBenefitsBenefitsUS from './en-US/benefitsBenefits'
import GetFeaturesHowToUS from './en-US/featuresHowTo'
import GetHeroHomeBannerUS from './en-US/heroHomeBanner'
import GetHeroPymesBannerUS from './en-US/heroPymesBanner'
import GetHeroNosotrosBannerUS from './en-US/heroNosotrosBanner'
import GetHeroEnterprisesBannerUS from './en-US/heroEnterprisesBanner'
import GetHeroInvestmentsBannerUS from './en-US/heroInvestmentsBanner'
import GetHeroImpactoBannerUS from './en-US/heroImpactoBanner'
import GetPurposeContentAreaUS from './en-US/purposeContentArea'
import GetHowToContentAreaUS from './en-US/howToContentArea'
import GetLiquidityContentAreaUS from './en-US/liquidityContentArea'
import GetReportContentAreaUS from './en-US/reportContentArea'
import GetPymesSolutionsFeaturesUS from './en-US/pymesSolutionsFeatures'
import GetAllyFormContentAreaUS from './en-US/allyFormContentArea'
import GetBenefitsEnterprisesFeaturesUS from './en-US/benefitsEnterprisesFeatures'
import GetProntoPagoFeaturesUS from './en-US/prontoPagoFeatures'
import GetHowToEnterprisesFeaturesUS from './en-US/howToEnterprisesFeatures'
import GetWhatMakesUsContentAreaUS from './en-US/whatMakesUsContentArea'
import GetNosotrosFeaturesUS from './en-US/nosotrosFeatures'
import GetTimelineFeaturesUS from './en-US/timelineFeatures'
import GetProductCardsFeaturesUS from './en-US/productCardsFeatures'
import GetFAQSobreCumploUS from './en-US/faqSobreCumplo'
import GetFAQInversionesUS from './en-US/faqInversiones'
import GetFAQSaldoCumploUS from './en-US/faqSaldoCumplo'
import GetFAQAnticiposUS from './en-US/faqAnticipos'
import GetStatsImpactoUS from './en-US/statsImpacto'
import GetFeaturesImpactoUS from './en-US/whatIsImpactoFeatures'
import GetImpactoCommitmentsFeaturesUS from './en-US/impactoCommitmentsFeatures'
import GetTermsConditionsUS from './en-US/termsConditions'
import GetPrivacyPolicyUS from './en-US/privacyPolicy'
import GetCookiesPolicyUS from './en-US/cookiesPolicy'

// Imports para los contenidos de MX

import GetSolutionsSliderMX from './es-MX/solutionsSlider'
import GetHeaderNavigationBarMX from './es-MX/headerNavigationBar'
import GetTestimonialsCarrouselHomeMX from './es-MX/testimonialsCarrouselHome'
import GetTestimonialsCarrouselPymesMX from './es-MX/testimonialsCarrouselPymes'
import GetTestimonialsCarrouselImpactoMX from './es-MX/testimonialsCarrouselImpacto'
import GetBenefitsBenefitsMX from './es-MX/benefitsBenefits'
import GetFeaturesHowToMX from './es-MX/featuresHowTo'
import GetHeroHomeBannerMX from './es-MX/heroHomeBanner'
import GetHeroPymesBannerMX from './es-MX/heroPymesBanner'
import GetHeroEnterprisesBannerMX from './es-MX/heroEnterprisesBanner'
import GetHeroNosotrosBannerMX from './es-MX/heroNosotrosBanner'
import GetHeroInvestmentsBannerMX from './es-MX/heroInvestmentsBanner'
import GetHeroImpactoBannerMX from './es-MX/heroImpactoBanner'
import GetPurposeContentAreaMX from './es-MX/purposeContentArea'
import GetHowToContentAreaMX from './es-MX/howToContentArea'
import GetLiquidityContentAreaMX from './es-MX/liquidityContentArea'
import GetReportContentAreaMX from './es-MX/reportContentArea'
import GetPymesSolutionsFeaturesMX from './es-MX/pymesSolutionsFeatures'
import GetAllyFormContentAreaMX from './es-MX/allyFormContentArea'
import GetBenefitsEnterprisesFeaturesMX from './es-MX/benefitsEnterprisesFeatures'
import GetProntoPagoFeaturesMX from './es-MX/prontoPagoFeatures'
import GetHowToEnterprisesFeaturesMX from './es-MX/howToEnterprisesFeatures'
import GetWhatMakesUsContentAreaMX from './es-MX/whatMakesUsContentArea'
import GetNosotrosFeaturesMX from './es-MX/nosotrosFeatures'
import GetTimelineFeaturesMX from './es-MX/timelineFeatures'
import GetProductCardsFeaturesMX from './es-MX/productCardsFeatures'
import GetFAQSobreCumploMX from './es-MX/faqSobreCumplo'
import GetFAQAnticiposMX from './es-MX/faqAnticipos'
import GetStatsImpactoMX from './es-MX/statsImpacto'
import GetFeaturesImpactoMX from './es-MX/whatIsImpactoFeatures'
import GetImpactoCommitmentsFeaturesMX from './es-MX/impactoCommitmentsFeatures'


// Imports para los contenidos de PE

import GetSolutionsSliderPE from './es-PE/solutionsSlider'
import GetHeaderNavigationBarPE from './es-PE/headerNavigationBar'
import GetTestimonialsCarrouselHomePE from './es-PE/testimonialsCarrouselHome'
import GetTestimonialsCarrouselPymesPE from './es-PE/testimonialsCarrouselPymes'
import GetTestimonialsCarrouselImpactoPE from './es-PE/testimonialsCarrouselImpacto'
import GetBenefitsBenefitsPE from './es-PE/benefitsBenefits'
import GetFeaturesHowToPE from './es-PE/featuresHowTo'
import GetHeroHomeBannerPE from './es-PE/heroHomeBanner'
import GetHeroPymesBannerPE from './es-PE/heroPymesBanner'
import GetHeroEnterprisesBannerPE from './es-PE/heroEnterprisesBanner'
import GetHeroNosotrosBannerPE from './es-PE/heroNosotrosBanner'
import GetHeroInvestmentsBannerPE from './es-PE/heroInvestmentsBanner'
import GetHeroImpactoBannerPE from './es-PE/heroImpactoBanner'
import GetPurposeContentAreaPE from './es-PE/purposeContentArea'
import GetHowToContentAreaPE from './es-PE/howToContentArea'
import GetLiquidityContentAreaPE from './es-PE/liquidityContentArea'
import GetReportContentAreaPE from './es-PE/reportContentArea'
import GetPymesSolutionsFeaturesPE from './es-PE/pymesSolutionsFeatures'
import GetAllyFormContentAreaPE from './es-PE/allyFormContentArea'
import GetBenefitsEnterprisesFeaturesPE from './es-PE/benefitsEnterprisesFeatures'
import GetProntoPagoFeaturesPE from './es-PE/prontoPagoFeatures'
import GetHowToEnterprisesFeaturesPE from './es-PE/howToEnterprisesFeatures'
import GetWhatMakesUsContentAreaPE from './es-PE/whatMakesUsContentArea'
import GetNosotrosFeaturesPE from './es-PE/nosotrosFeatures'
import GetTimelineFeaturesPE from './es-PE/timelineFeatures'
import GetProductCardsFeaturesPE from './es-PE/productCardsFeatures'
import GetFAQSobreCumploPE from './es-PE/faqSobreCumplo'
import GetFAQInversionesPE from './es-PE/faqInversiones'
import GetFAQAnticiposPE from './es-PE/faqAnticipos'
import GetStatsImpactoPE from './es-PE/statsImpacto'
import GetFeaturesImpactoPE from './es-PE/whatIsImpactoFeatures'
import GetImpactoCommitmentsFeaturesPE from './es-PE/impactoCommitmentsFeatures'
import GetTermsConditionsPE from './es-PE/termsConditions'
import GetPrivacyPolicyPE from './es-PE/privacyPolicy'
import GetCookiesPolicyPE from './es-PE/cookiesPolicy'

// Imports para los contenidos de CO

import GetSolutionsSliderCO from './es-CO/solutionsSlider'
import GetHeaderNavigationBarCO from './es-CO/headerNavigationBar'
import GetTestimonialsCarrouselHomeCO from './es-CO/testimonialsCarrouselHome'
import GetTestimonialsCarrouselPymesCO from './es-CO/testimonialsCarrouselPymes'
import GetBenefitsBenefitsCO from './es-CO/benefitsBenefits'
import GetFeaturesHowToCO from './es-CO/featuresHowTo'
import GetHeroHomeBannerCO from './es-CO/heroHomeBanner'
import GetHeroPymesBannerCO from './es-CO/heroPymesBanner'
import GetHeroEnterprisesBannerCO from './es-CO/heroEnterprisesBanner'
import GetHeroNosotrosBannerCO from './es-CO/heroNosotrosBanner'
import GetPurposeContentAreaCO from './es-CO/purposeContentArea'
import GetHowToContentAreaCO from './es-CO/howToContentArea'
import GetLiquidityContentAreaCO from './es-CO/liquidityContentArea'
import GetPymesSolutionsFeaturesCO from './es-CO/pymesSolutionsFeatures'
import GetAllyFormContentAreaCO from './es-CO/allyFormContentArea'
import GetBenefitsEnterprisesFeaturesCO from './es-CO/benefitsEnterprisesFeatures'
import GetProntoPagoFeaturesCO from './es-CO/prontoPagoFeatures'
import GetHowToEnterprisesFeaturesCO from './es-CO/howToEnterprisesFeatures'
import GetWhatMakesUsContentAreaCO from './es-CO/whatMakesUsContentArea'
import GetNosotrosFeaturesCO from './es-CO/nosotrosFeatures'

const components = {
    
 "header-navigation-bar-es-CL": GetHeaderNavigationBarCL,
 "header-navigation-bar-es-PE": GetHeaderNavigationBarPE,
 "header-navigation-bar-es-MX": GetHeaderNavigationBarMX,
 "header-navigation-bar-es-CO": GetHeaderNavigationBarCO,
 "header-navigation-bar-en-US": GetHeaderNavigationBarUS,

 "solutions-slider-es-CL": GetSolutionsSliderCL,
 "solutions-slider-es-PE": GetSolutionsSliderPE,
 "solutions-slider-es-MX": GetSolutionsSliderMX,
 "solutions-slider-es-CO": GetSolutionsSliderCO,
 "solutions-slider-en-US": GetSolutionsSliderUS,

 "testimonials-carrousel-home-es-CL": GetTestimonialsCarrouselHomeCL,
 "testimonials-carrousel-home-es-PE": GetTestimonialsCarrouselHomePE,
 "testimonials-carrousel-home-es-MX": GetTestimonialsCarrouselHomeMX,
 "testimonials-carrousel-home-es-CO": GetTestimonialsCarrouselHomeCO,
 "testimonials-carrousel-home-en-US": GetTestimonialsCarrouselHomeUS,

 "testimonials-carrousel-pymes-es-CL": GetTestimonialsCarrouselPymesCL,
 "testimonials-carrousel-pymes-es-PE": GetTestimonialsCarrouselPymesPE,
 "testimonials-carrousel-pymes-es-MX": GetTestimonialsCarrouselPymesMX,
 "testimonials-carrousel-pymes-es-CO": GetTestimonialsCarrouselPymesCO,
 "testimonials-carrousel-pymes-en-US": GetTestimonialsCarrouselPymesUS,

 "testimonials-carrousel-impacto-es-CL": GetTestimonialsCarrouselImpactoCL,
 "testimonials-carrousel-impacto-es-PE": GetTestimonialsCarrouselImpactoPE,
 "testimonials-carrousel-impacto-es-MX": GetTestimonialsCarrouselImpactoMX,
 "testimonials-carrousel-impacto-en-US": GetTestimonialsCarrouselImpactoUS,

 "benefits-benefits-es-CL": GetBenefitsBenefitsCL,
 "benefits-benefits-es-PE": GetBenefitsBenefitsPE,
 "benefits-benefits-es-MX": GetBenefitsBenefitsMX,
 "benefits-benefits-es-CO": GetBenefitsBenefitsCO,
 "benefits-benefits-en-US": GetBenefitsBenefitsUS,

 "features-how-to-es-CL": GetFeaturesHowToCL,
 "features-how-to-es-PE": GetFeaturesHowToPE,
 "features-how-to-es-MX": GetFeaturesHowToMX,
 "features-how-to-es-CO": GetFeaturesHowToCO,
 "features-how-to-en-US": GetFeaturesHowToUS,

 "hero-home-banner-es-CL": GetHeroHomeBannerCL,
 "hero-home-banner-es-PE": GetHeroHomeBannerPE,
 "hero-home-banner-es-MX": GetHeroHomeBannerMX,
 "hero-home-banner-es-CO": GetHeroHomeBannerCO,
 "hero-home-banner-en-US": GetHeroHomeBannerUS,

 "hero-pymes-banner-es-CL": GetHeroPymesBannerCL,
 "hero-pymes-banner-es-PE": GetHeroPymesBannerPE,
 "hero-pymes-banner-es-MX": GetHeroPymesBannerMX,
 "hero-pymes-banner-es-CO": GetHeroPymesBannerCO,
 "hero-pymes-banner-en-US": GetHeroPymesBannerUS,

 "hero-nosotros-banner-es-CL": GetHeroNosotrosBannerCL,
 "hero-nosotros-banner-es-PE": GetHeroNosotrosBannerPE,
 "hero-nosotros-banner-es-MX": GetHeroNosotrosBannerMX,
 "hero-nosotros-banner-es-CO": GetHeroNosotrosBannerCO,
 "hero-nosotros-banner-en-US": GetHeroNosotrosBannerUS,

 "hero-enterprises-banner-es-CL": GetHeroEnterprisesBannerCL,
 "hero-enterprises-banner-es-PE": GetHeroEnterprisesBannerPE,
 "hero-enterprises-banner-es-MX": GetHeroEnterprisesBannerMX,
 "hero-enterprises-banner-es-CO": GetHeroEnterprisesBannerCO,
 "hero-enterprises-banner-en-US": GetHeroEnterprisesBannerUS,

 "hero-impacto-banner-es-CL": GetHeroImpactoBannerCL,
 "hero-impacto-banner-es-PE": GetHeroImpactoBannerPE,
 "hero-impacto-banner-es-MX": GetHeroImpactoBannerMX,
 "hero-impacto-banner-en-US": GetHeroImpactoBannerUS,

 "purpose-content-area-es-CL": GetPurposeContentAreaCL,
 "purpose-content-area-es-PE": GetPurposeContentAreaPE,
 "purpose-content-area-es-MX": GetPurposeContentAreaMX,
 "purpose-content-area-es-CO": GetPurposeContentAreaCO,
 "purpose-content-area-en-US": GetPurposeContentAreaUS,

 "liquidity-content-area-es-CL": GetLiquidityContentAreaCL,
 "liquidity-content-area-es-PE": GetLiquidityContentAreaPE,
 "liquidity-content-area-es-MX": GetLiquidityContentAreaMX,
 "liquidity-content-area-es-CO": GetLiquidityContentAreaCO,
 "liquidity-content-area-en-US": GetLiquidityContentAreaUS,

 "how-to-content-area-es-CL": GetHowToContentAreaCL,
 "how-to-content-area-es-PE": GetHowToContentAreaPE,
 "how-to-content-area-es-MX": GetHowToContentAreaMX,
 "how-to-content-area-es-CO": GetHowToContentAreaCO,
 "how-to-content-area-en-US": GetHowToContentAreaUS,

 "pymes-solutions-features-es-CL": GetPymesSolutionsFeaturesCL,
 "pymes-solutions-features-es-PE": GetPymesSolutionsFeaturesPE,
 "pymes-solutions-features-es-MX": GetPymesSolutionsFeaturesMX,
 "pymes-solutions-features-es-CO": GetPymesSolutionsFeaturesCO,
 "pymes-solutions-features-en-US": GetPymesSolutionsFeaturesUS,

 "ally-form-content-area-es-CL": GetAllyFormContentAreaCL,
 "ally-form-content-area-es-PE": GetAllyFormContentAreaPE,
 "ally-form-content-area-es-MX": GetAllyFormContentAreaMX,
 "ally-form-content-area-es-CO": GetAllyFormContentAreaCO,
 "ally-form-content-area-en-US": GetAllyFormContentAreaUS,

 "benefits-enterprises-features-es-CL": GetBenefitsEnterprisesFeaturesCL,
 "benefits-enterprises-features-es-PE": GetBenefitsEnterprisesFeaturesPE,
 "benefits-enterprises-features-es-MX": GetBenefitsEnterprisesFeaturesMX,
 "benefits-enterprises-features-es-CO": GetBenefitsEnterprisesFeaturesCO,
 "benefits-enterprises-features-en-US": GetBenefitsEnterprisesFeaturesUS,

 "pronto-pago-features-es-CL": GetProntoPagoFeaturesCL,
 "pronto-pago-features-es-PE": GetProntoPagoFeaturesPE,
 "pronto-pago-features-es-MX": GetProntoPagoFeaturesMX,
 "pronto-pago-features-es-CO": GetProntoPagoFeaturesCO,
 "pronto-pago-features-en-US": GetProntoPagoFeaturesUS,

 "how-to-enterprises-features-es-CL": GetHowToEnterprisesFeaturesCL,
 "how-to-enterprises-features-es-PE": GetHowToEnterprisesFeaturesPE,
 "how-to-enterprises-features-es-MX": GetHowToEnterprisesFeaturesMX,
 "how-to-enterprises-features-es-CO": GetHowToEnterprisesFeaturesCO,
 "how-to-enterprises-features-en-US": GetHowToEnterprisesFeaturesUS,

 "what-makes-us-content-area-es-CL": GetWhatMakesUsContentAreaCL,
 "what-makes-us-content-area-es-PE": GetWhatMakesUsContentAreaPE,
 "what-makes-us-content-area-es-MX": GetWhatMakesUsContentAreaMX,
 "what-makes-us-content-area-es-CO": GetWhatMakesUsContentAreaCO,
 "what-makes-us-content-area-en-US": GetWhatMakesUsContentAreaUS,

 "nosotros-features-es-CL": GetNosotrosFeaturesCL,
 "nosotros-features-es-PE": GetNosotrosFeaturesPE,
 "nosotros-features-es-MX": GetNosotrosFeaturesMX,
 "nosotros-features-es-CO": GetNosotrosFeaturesCO,
 "nosotros-features-en-US": GetNosotrosFeaturesUS,

 "timeline-features-es-CL": GetTimelineFeaturesCL,
 "timeline-features-es-PE": GetTimelineFeaturesPE,
 "timeline-features-es-MX": GetTimelineFeaturesMX,
 "timeline-features-en-US": GetTimelineFeaturesUS,

 "product-cards-features-es-CL": GetProductCardsFeaturesCL,
 "product-cards-features-es-PE": GetProductCardsFeaturesPE,
 "product-cards-features-es-MX": GetProductCardsFeaturesMX,
 "product-cards-features-en-US": GetProductCardsFeaturesUS,

 "hero-investments-banner-es-CL": GetHeroInvestmentsBannerCL,
 "hero-investments-banner-es-PE": GetHeroInvestmentsBannerPE,
 "hero-investments-banner-es-MX": GetHeroInvestmentsBannerMX,
 "hero-investments-banner-en-US": GetHeroInvestmentsBannerUS,

 "faq-sobre-cumplo-es-CL": GetFAQSobreCumploCL,
 "faq-sobre-cumplo-es-PE": GetFAQSobreCumploPE,
 "faq-sobre-cumplo-es-MX": GetFAQSobreCumploMX,
 "faq-sobre-cumplo-en-US": GetFAQSobreCumploUS,

 "faq-inversiones-es-CL": GetFAQInversionesCL,
 "faq-inversiones-es-PE": GetFAQInversionesPE,
 "faq-inversiones-en-US": GetFAQInversionesUS,
 
 "faq-saldo-cumplo-es-CL": GetFAQSaldoCumploCL,
 "faq-saldo-cumplo-en-US": GetFAQSaldoCumploUS,

 "faq-anticipos-es-CL": GetFAQAnticiposCL,
 "faq-anticipos-es-PE": GetFAQAnticiposPE,
 "faq-anticipos-es-MX": GetFAQAnticiposMX,
 "faq-anticipos-en-US": GetFAQAnticiposUS,

 "report-content-area-es-CL": GetReportContentAreaCL,
 "report-content-area-es-PE": GetReportContentAreaPE,
 "report-content-area-es-MX": GetReportContentAreaMX,
 "report-content-area-en-US": GetReportContentAreaUS,

 "stats-impacto-es-CL": GetStatsImpactoCL,
 "stats-impacto-es-PE": GetStatsImpactoMX,
 "stats-impacto-es-MX": GetStatsImpactoPE,
 "stats-impacto-en-US": GetStatsImpactoUS,

 "what-is-impacto-features-es-CL": GetFeaturesImpactoCL,
 "what-is-impacto-features-es-MX": GetFeaturesImpactoMX,
 "what-is-impacto-features-es-PE": GetFeaturesImpactoPE,
 "what-is-impacto-features-en-US": GetFeaturesImpactoUS,

 "impacto-commitments-features-es-CL": GetImpactoCommitmentsFeaturesCL,
 "impacto-commitments-features-es-MX": GetImpactoCommitmentsFeaturesMX,
 "impacto-commitments-features-es-PE": GetImpactoCommitmentsFeaturesPE,
 "impacto-commitments-features-en-US": GetImpactoCommitmentsFeaturesUS,

 "terms-conditions-es-CL": GetTermsConditionsCL,
 "terms-conditions-es-PE": GetTermsConditionsPE,
 "terms-conditions-en-US": GetTermsConditionsUS,

 "privacy-policy-es-CL": GetPrivacyPolicyCL,
 "privacy-policy-es-PE": GetPrivacyPolicyPE,
 "privacy-policy-en-US": GetPrivacyPolicyUS,

 "cookies-policy-es-CL": GetCookiesPolicyCL,
 "cookies-policy-es-PE": GetCookiesPolicyPE,
 "cookies-policy-en-US": GetCookiesPolicyUS,


};

const getContent = (content, country) => {
 var Content = components[`${content}-${country}`]
 return Content();
}

export { getContent }
