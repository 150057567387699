import React from "react";
import { Acordeon, ContenidoTextoEnriquecidoNuevo } from "src/templates";

const PreguntaAcordeonNuevo = ({ titulo, contenidos, AcordeonProps }) => {
 return (
  <Acordeon titulo={titulo} {...AcordeonProps}>
   <ContenidoTextoEnriquecidoNuevo contenidos={contenidos} />
  </Acordeon>
 );
};

export { PreguntaAcordeonNuevo };
export default PreguntaAcordeonNuevo;
