import { useState } from "react";
import axios from "axios";
import { coerceBooleanProperty, coerceStringProperty } from "../utils/coercion";
import { cookie } from "cumplo_utils";
import * as qs from "qs";
import { ENV } from "../config/env";
import { isCountryPeru } from "../utils/country";
import { addfixedHeaders } from "../utils/addFixedHeaders";

const host = process.env.GATSBY_HOST_URL;

export const toCompanies = (participants) => {
    const data = participants.map((participant) => {
        const {
            numero_identificador,
            tipo_participante,
            nombre_fantasia,
            persona_nombre,
            persona_primer_apellido,
            telefono,
            email,
        } = participant.attributes || {};
        let name = "";
        if (tipo_participante === "JURIDICA" || tipo_participante === null) {
            name = nombre_fantasia || "Predeterminada";
        } else if (tipo_participante === "NATURAL") {
            name = `${persona_nombre || "Default"} ${
                persona_primer_apellido || "Default"
            }`;
        }
        return { id: participant?.id, name, first_name: persona_nombre, last_name: persona_primer_apellido, numberId: numero_identificador, phone: telefono, email };
    });
    return data;
};

const createQueryFilter = (investorId) => {
    const isProduction = coerceBooleanProperty(ENV.isProduction);
    const country = isCountryPeru() ? "Perú" : "Chile";
    return qs.stringify({
        filters: {
            owner: investorId,
            $and: [{ pais: { nombre: { $eq: country } } }],
            ...(isCountryPeru() && {
                $and: [
                    {
                        tipo_participante: { $eq: "NATURAL" },
                        pais: { nombre: { $eq: country } },
                    },
                ],
            }),
        },
    });
};

export const getCompaniesByInvestorIdPromise = (investorId) => {
    const query = createQueryFilter(investorId);
    const url = `${host}/empresas?${query}`;
    const { value: jwt } = cookie.getCookie("cumploSession");
    const config = {
        headers: { Authorization: `Bearer ${jwt}`, ...addfixedHeaders() },
    };
    return axios.get(url, config);
};

export const useCompanyByInvestorId = () => {
    const [response, setResponse] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const getCompaniesByInvestorId = (investorId) => {
        setLoading(true);
        setError("");
        setResponse([]);
        getCompaniesByInvestorIdPromise(investorId)
            .then((resp) => {
                const data = toCompanies(resp.data?.data || []);
                setResponse(data);
            })
            .catch((err) => {
                console.log(err);
                const textError = coerceStringProperty(
                    err?.response?.data?.error?.message,
                    "Error de servidor"
                );
                setError(textError);
            })
            .finally(() => setLoading(false));
    };

    const reset = () => {
        setResponse([]);
    };

    return { response, loading, error, getCompaniesByInvestorId, reset };
};
