import { graphql, useStaticQuery } from 'gatsby'

const GetFeaturesHowToUS = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures (
        filter: {name: {eq: "Pasos para unirte a la red Cumplo"}, node_locale: {eq: "en-US"}}
    ){
      nodes {
        title
        features {
          number
          title
          body: description
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]

 return results

}

export default GetFeaturesHowToUS
