import { graphql, useStaticQuery } from 'gatsby'

const GetPymesSolutionsFeaturesCO = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "Soluciones para pymes"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        title
        features {
          image {
            file {
              url
            }
          }
          icon
          title
          body {
            internal {
              content
            }
          }
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetPymesSolutionsFeaturesCO
