import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import { createTheme } from "@mui/material/styles";

const CumploBranding = createTheme({
 ...palette,
 ...typography,
 ...overrides,
 ...breakpoints,
});

export { CumploBranding };