import { graphql, useStaticQuery } from 'gatsby'

const GetHeaderNavigationBarUS = () => {

 const { allContentfulNavigationBar } = useStaticQuery(graphql`
 {
    allContentfulNavigationBar(
      filter: {name: {eq: "Header"}, node_locale: {eq: "en-US"}}
    ) {
      nodes {
        callToAction {
          text
          link
        }
        countrySelector
        navigationLinks {
          text
          link
          dropdownLinks {
            text
            link
          }
        }
      }
    }
  }
        `)
 const results = allContentfulNavigationBar?.nodes[0]

 return results

}

export default GetHeaderNavigationBarUS
