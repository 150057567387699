import { graphql, useStaticQuery } from 'gatsby'

const GetHeaderNavigationBarMX = () => {

 const { allContentfulNavigationBar } = useStaticQuery(graphql`
 {
    allContentfulNavigationBar(
      filter: {name: {eq: "Header"}, node_locale: {eq: "es-MX"}}
    ) {
      nodes {
        callToAction {
          text
          link
        }
        countrySelector
        navigationLinks {
          text
          link
          dropdownLinks {
            text
            link
          }
        }
      }
    }
  }
        `)
 const results = allContentfulNavigationBar?.nodes[0]
   
 return results
   
}
   
export default GetHeaderNavigationBarMX
