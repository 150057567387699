import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
 root: {
  background: `${theme.palette.info.light} !important`,
  boxShadow: "0px 10px 4px rgba(0, 0, 0, 0.1) !important",
  borderRadius: "8px !important",
  marginLeft: "20px !important",

  [theme.breakpoints.up("md")]: {
   display: "flex !important",
   marginTop: "20px !important",
   marginLeft: "0px !important",
  },
 },
 stepContainer: {
  display: "flex",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.up("md")]: {
   flexDirection: "column",
  },
 },
 content: {
  marginLeft: "16px !important",
  marginRight: "8px !important",
  marginBottom: "24px !important",
  width: "100%",
  [theme.breakpoints.up("md")]: {
   margin: 0,
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   justifyContent: "center",
  },
 },
 iconContainer: {
  marginLeft: "-46px",
  [theme.breakpoints.up("md")]: {
   marginTop: "-50px",
   marginLeft: "0px",
  },
 },
 numberContainer: {
  borderRadius: "100%",
  margin: "0 auto",
  width: "60px",
  height: "60px",
  boxShadow: "0px 10px 4px rgba(0, 0, 0, 0.1)",
  backgroundColor: theme.palette.secondary.main,
 },
 number: {
  textAlign: "center",
  lineHeight: "60px !important",
  color: theme.palette.primary.main,
 },
 title: {
  color: theme.palette.primary.main,
  marginTop: "24px !important",
  textAlign: "center",
  minHeight: "46px !important",
 },
 body: {
  color: theme.palette.text.primary,
  marginTop: "16px !important",

  [theme.breakpoints.up("md")]: {
   margin: "24px 12px 0px !important",
  },
  [theme.breakpoints.up("lg")]: {
   margin: "24px 12px 0px !important",
  },
  [theme.breakpoints.up("xl")]: {
   margin: "24px 12px 0px !important",
  },
 },
 divider: {
  borderWidth: "1px",
  borderColor: theme.palette.info.main,
  background: theme.palette.info.main,
  margin: "0px 10px",
  [theme.breakpoints.up("md")]: {
   margin: "10px 0px",
  },
 },
}));
export default useStyles;