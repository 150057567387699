import { graphql, useStaticQuery } from 'gatsby'

const GetTermsConditionsUS = () => {

 const { allContentfulTermsAndRegulations } = useStaticQuery(graphql`
 {
  allContentfulTermsAndRegulations(
    sort: {fields: order, order: ASC},
    filter: {category: {eq: "Términos y condiciones"}, node_locale: {eq: "en-US"}}
  ) {
    nodes {
      title
      body {
        raw
        references {
          file {
            url
          }
          contentful_id
        }
      }
    }
  }
}
        `)
 const results = allContentfulTermsAndRegulations?.nodes

 return results

}

export default GetTermsConditionsUS
