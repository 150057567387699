import { createTheme } from '@mui/material/styles';
import breakpoints from './breakpoints'
const theme = createTheme(breakpoints);

const typography = {
 typography: {
  fontFamily: "Montserrat",
  h1: {
   fontSize: 30,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 29,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 27,
    letterSpacing: 0,
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 19,
    letterSpacing: 0,
   },
   fontWeight: 700,
   "& mark": {
    fontWeight: 400,
    color: "inherit",
    background: "inherit"
   },
  },
  h2: {
   fontSize: 30,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 29,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 27,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 19,
    letterSpacing: "1.5px",
   },
   fontWeight: 400,
  },
  h3: {
   fontSize: 19,
   letterSpacing: "1.3px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 19,
    letterSpacing: "3px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 19,
    letterSpacing: "3px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 19,
    letterSpacing: "3px",
   },
   fontWeight: 700,
  },
  h4: {
   fontSize: 19,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 19,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    letterSpacing: "1.5px",
   },
   fontWeight: 600,
  },
  h5: {
   fontSize: 19,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 18,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    letterSpacing: "1.5px",
   },
   fontWeight: 500,
  },
  h6: {
   fontSize: 18,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 18,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 18,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    letterSpacing: "1.5px",
   },
   fontWeight: 400,
  },
  subtitle1: {
   fontFamily: "Poppins",
   fontSize: 19,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   fontWeight: 700,
  },
  subtitle2: {
   fontFamily: "Poppins",
   fontSize: 19,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 17,
    letterSpacing: "1.5px",
   },
   fontStyle: "italic",
   fontWeight: 500,
  },
  body1: {
   fontFamily: "Poppins",
   fontSize: 18,
   letterSpacing: 0,
   [theme.breakpoints.down("lg")]: {
    fontSize: 17,
    letterSpacing: 0,
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    letterSpacing: 0,
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 17,
    letterSpacing: 0,
   },
   fontWeight: 400,
  },
  body2: {
   fontFamily: "Poppins",
   fontSize: 17,
   letterSpacing: 0,
   [theme.breakpoints.down("lg")]: {
    fontSize: 14,
    letterSpacing: 0,
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 12,
    letterSpacing: 0,
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    letterSpacing: 0,
   },
   fontWeight: 400,
  },
  button: {
   fontSize: 17,
   letterSpacing: "1.25px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 17,
    letterSpacing: "1.25px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 17,
    letterSpacing: "1.25px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 15,
    letterSpacing: "1.25px",
   },
   fontWeight: 700,
   textTransform: 'uppercase',
  },
  caption: {
   fontFamily: "Poppins",
   fontSize: 12,
   letterSpacing: "0.4px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 12,
    letterSpacing: "0.4px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 12,
    letterSpacing: "0.4px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    letterSpacing: "0.4px",
   },
   fontWeight: 400,
  },
  overline: {
   fontFamily: "Poppins",
   fontSize: 10,
   letterSpacing: "1.5px",
   [theme.breakpoints.down("lg")]: {
    fontSize: 10,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("md")]: {
    fontSize: 10,
    letterSpacing: "1.5px",
   },
   [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    letterSpacing: "1.5px",
   },
   fontWeight: 400,
   textTransform: 'uppercase',
  },
 },
};

export default typography;