const overrides = {
 components: {
  MuiContainer: {
   styleOverrides: {
    root: {
     maxWidth: "1440px !important"
    },
   },
  },
  MuiListItem: {
   styleOverrides: {
    root: {
     width: 'fit-content !important'
    },
   },
  },
  MuiPopover: {
   styleOverrides: {
    paper: {
     borderRadius: "16px !important",
    }
   }
  },
  MuiOutlinedInput: {
   styleOverrides: {
    notchedOutline: {
     borderRadius: "16px !important",
    }
   }
  },
 },
};

export default overrides;
