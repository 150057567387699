import { graphql, useStaticQuery } from 'gatsby'

const GetLiquidityContentAreaCO = () => {

 const { allContentfulContentArea } = useStaticQuery(graphql`
 {
    allContentfulContentArea (
  filter: {name: {eq: "¿Necesitas liquidez?"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        title
        body {
            internal {
              content
            }
          }
        images {
          file {
            url
          }
        }
        callToAction {
          text
          link
        }
      }
    }
  }
        `)
 const results = allContentfulContentArea?.nodes[0]
   
 return results
   
}
   
export default GetLiquidityContentAreaCO
