import { graphql, useStaticQuery } from 'gatsby'

const GetAllyFormContentAreaCO = () => {

 const { allContentfulContentArea } = useStaticQuery(graphql`
 {
    allContentfulContentArea (
  filter: {name: {eq: "¿Nos aliamos?"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        title
        body {
            internal {
                content
            }
        }
        images {
          file {
            url
          }
        }
      }
    }
  }
        `)
 const results = allContentfulContentArea?.nodes[0]
   
 return results
   
}
   
export default GetAllyFormContentAreaCO
