import React , { useEffect }  from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles';

const ExpandableMenu = ({text, link, dropdownLinks, location}) => {
 const theme = useTheme();
 const [anchorEl, setAnchorEl] = React.useState(null);
 const [isActive, setActive] = React.useState(false);
 function handleClick(event) {
  if (anchorEl !== event.currentTarget) {
   setAnchorEl(event.currentTarget);
  }
 }

 useEffect(() => {
  if (location?.pathname === (link + "/")) {
   setActive(true)
  }
 }, [link, location]);

 function handleClose() {
  setAnchorEl(null);
 }

 return (
  <>
   <Button
    aria-owns={anchorEl ? "simple-menu" : undefined}
    aria-haspopup="true"
    onClick={handleClick}
    sx={{padding: 0, borderRadius: 0, borderBottom: isActive ? "2px solid " + theme.palette.secondary.main : "none" }}
   >
    {dropdownLinks ? (
     <Typography variant="button" sx={{ textTransform: "capitalize" }} color={isActive ? theme.palette.secondary.main : theme.palette.text.primary} align="center">
      {text}
     </Typography>
    ) : (
     <a href={link} style={{ textDecoration: 'none'}}>
      <Typography variant="button" sx={{ textTransform: "capitalize" }} color={isActive ? theme.palette.secondary.main : theme.palette.text.primary} align="center">
       {text}
      </Typography>
     </a>
    )}
     
    {dropdownLinks && (
     Boolean(anchorEl) ? (
      <KeyboardArrowUp sx={{color: theme.palette.text.primary, width: 20, height: 20, marginLeft: "4px"}}/>
     ) : (
      <KeyboardArrowDown sx={{color: theme.palette.text.primary, width: 20, height: 20, marginLeft: "4px"}}/>
     )
    )}
   </Button>
   {dropdownLinks && (
    <Menu
     anchorEl={anchorEl}
     open={Boolean(anchorEl)}
     onClose={handleClose}
    >
     {dropdownLinks?.map((item, index) => (
      <a key={index} href={item.link} style={{color: theme.palette.text.primary, textDecoration: "none"}}>
       <MenuItem key={item.text} onClick={handleClose}>{item.text}</MenuItem>
      </a>
     ))}
    </Menu>
   )}
  </>
 );
};

export { ExpandableMenu };
