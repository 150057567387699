import React from "react";
import PropTypes from "prop-types";
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Icon } from "src/templates"
import { useTheme } from '@mui/material/styles';
import LoadingButton from "@mui/lab/LoadingButton";

const Button = ({
 icon,
 action,
 disabled,
 children,
 loading,
 color,
 variant,
 wrap = false,
 ...rest
}) => {

 const theme = useTheme();

 const styles = {
  buttonStyles: {
   height: wrap ? "auto" : "39px !important",
   alignItems: "center",
   paddingLeft: variant !== "text" ? "23px !important" : "0px !important",
   paddingRight: variant !== "text" ? "23px !important" : "0px !important",
   borderRadius: "8px !important",
   border: variantButtonBorder(variant),
   textDecoration: 'none !important',
   background: variantButtonBackground(variant),
   "&:hover": {
    background: variantButtonBackgroundHover(variant),
    color: variantButtonTextHover(variant),
    border: variantButtonBorderHover(variant)
   },
   "&.Mui-disabled": {
    color: theme.palette.text.secondary,
    background: theme.palette.text.disabled,
    border: 'none',
    opacity: 0.5
   },
   "&.MuiLoadingButton-loading": {
    color: `transparent`,
    "&>div": {
     color: theme.palette.text.secondary,
    }
   },
   color: variantButtonText(variant),
   minWidth: variant === "text" ? null : '150px',
  }
 }

 function variantButtonText(param) {
  switch(param) {
  case 'text':
   return `${theme.palette.secondary.main}`
  case 'contained':
   return `${theme.palette.text.secondary}`
  case 'outlined':
   return `${theme.palette.secondary.main}`
  case 'contrast':
   return `${theme.palette.text.secondary}`
  default:
   return null;
  }
 }

 function variantButtonTextHover(param) {
  switch(param) {
  case 'text':
   return `${theme.palette.secondary.dark} !important`
  case 'contained':
   return `${theme.palette.text.secondary}`
  case 'outlined':
   return `${theme.palette.secondary.dark}`
  case 'contrast':
   return `${theme.palette.secondary.dark}`
  default:
   return null;
  }
 }


 function variantButtonBackground(param) {
  switch(param) {
  case 'text':
   return `transparent`
  case 'contained':
   return `${theme.palette.secondary.main}`
  case 'outlined':
   return `transparent`
  case 'contrast':
   return `transparent`
  default:
   return null;
  }
 }

 function variantButtonBackgroundHover(param) {
  switch(param) {
  case 'text':
   return `transparent`
  case 'contained':
   return `${theme.palette.secondary.dark}`
  case 'outlined':
   return `${theme.palette.secondary.dark}30`
  case 'contrast':
   return `${theme.palette.text.secondary}`
  default:
   return null;
  }
 }

 function variantButtonBorder(param) {
  switch(param) {
  case 'text':
   return `transparent`
  case 'contained':
   return `1px solid ${theme.palette.secondary.main}`
  case 'outlined':
   return `1px solid ${theme.palette.secondary.main}`
  case 'contrast':
   return `1px solid ${theme.palette.text.secondary}`
  default:
   return null;
  }
 }

 function variantButtonBorderHover(param) {
  switch(param) {
  case 'text':
   return `1px solid ${theme.palette.text.secondary}`
  case 'contained':
   return `1px solid ${theme.palette.text.secondary}`
  case 'outlined':
   return `1px solid ${theme.palette.secondary.dark}`
  case 'contrast':
   return `${theme.palette.text.secondary}`
  default:
   return null;
  }
 }

 return (
  <LoadingButton
   onClick={action}
   disabled={disabled}
   {...rest}
   sx={styles.buttonStyles}
   loading={loading}
  >
   {icon && (
    <Box style={{ marginRight: "8px", marginBottom: "-4px" }}>
     <Icon name={icon} width="20px" color={variantButtonTextHover(variant)} />
    </Box>
   )}
   <Typography noWrap={ wrap ? false : true} variant="button" color={color}>{children}</Typography>
  </LoadingButton>
 );
};

Button.propTypes = {
 action: PropTypes.func,
 disabled: PropTypes.bool,
 children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
 loading: PropTypes.bool,
 variant: PropTypes.oneOf([
  "text",
  "contained",
  "outlined",
  "contrast",
 ])
};

Button.defaultProps = {
 action: () => {},
 disabled: false,
 children: "",
 color: "inherit",
 loading: false,
 variant: "text"
};

export { Button };