import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
 typography: {
  objectFit: "cover",
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
 },
}));

export default useStyles;