import { getCountryCurrencies } from './currency';

const VALUES_INVALID = [undefined, "null", null, "undefined", "", "None"];
const MAX_MIN_FRACTIONS = {
  USD: { min: 2, max: 2 },
  PEN: { min: 2, max: 2 },
  CLP: { min: 0, max: 0 },
  CL: { min: 0, max: 0 }, // TODO PARCHE
}

export const coerceStringProperty = (value, defaultValue = '') => {
  if (typeof value === 'string' && !VALUES_INVALID.includes(value)) {
    return value;
  } else {
    return defaultValue;
  }
}

export const coerceDateLongProperty = (value, defaultValue = '') => {
  const date = coerceStringProperty(value, null);
  if (date) {
    return new Date(value).toLocaleDateString();
  }
  return defaultValue;
}

export const coerceNumberProperty = (value, defaultValue = 0) => {
  if (typeof value === 'number' && !VALUES_INVALID.includes(value)) {
    return value;
  } else if (typeof value === 'string' && !VALUES_INVALID.includes(value) && !isNaN(value)) {
    return Number(value);
  } else {
    return defaultValue;
  }
}

export const coerceNumberWithDecimalsProperty = (value, locale, fractionDecimals = 2) => {
  const amount = coerceNumberProperty(value, 0);
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDecimals,
    maximumFractionDigits: fractionDecimals,
  }).format(amount);
}

export const coerceCurrencyProperty = (value, currencyCode, {minFraction, maxFraction} = {}) => {
  const currency = getCountryCurrencies().filter(x => x.currencyCode ===  currencyCode)[0];
  const amount = coerceNumberProperty(value, false);
  const environmentCountry = typeof window !== 'undefined' ? window.environmentCountry : '';

  if (!environmentCountry || amount === false || !currency) return '';

  const fractions = MAX_MIN_FRACTIONS[currencyCode];
  return new Intl.NumberFormat(currency.locale, {
    minimumFractionDigits: minFraction || fractions.min,
    maximumFractionDigits: maxFraction || fractions.max,
    currency: currency.currencyCode === 'CL' ? 'CLP' : currency.currencyCode, // TODO PARCHE
    style: 'currency',
  }).format(amount);
};

export const coerceBooleanProperty = (value, defaultValue) => {
  switch (true) {
    case typeof value === 'boolean':
      return value;
    case typeof value === 'number' && [1, 0].includes(value):
      return Boolean(value);
    case typeof value === 'string' && ['1', '0'].includes(value):
      return Boolean(Number(value));
    case typeof value === 'string' && ['false', 'true'].includes(value.toLowerCase()):
      return value.toLowerCase() === 'true';
    case VALUES_INVALID.includes(value):
      return defaultValue;
    default:
      return !!value
  }
}
