import React from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import { navigate } from "gatsby";
import { CLIENT_IS_LOGGED_IN, LOGOUT } from "src/utils/queries";
import { getCookie } from "src/utils/validations";
import { session } from "cumplo_utils";
import { getUrlPrivateSite } from "../../../utils/privateSite";

export const UserMenu = (isLoggedin) => {
    const client = useApolloClient();
    const theme = useTheme();
    const [logout] = useMutation(LOGOUT, {
        onCompleted(data) {
            client.writeQuery({
                query: CLIENT_IS_LOGGED_IN,
                data: {
                    isLoggedIn: false,
                },
            });
            navigate("/");
        },
        context: {
            headers: {
                Authorization: `Token ${getCookie("tkn")}`,
            },
        },
    });

    const closeSession = () => {
        session.closeSession();
        window.location.reload();
    };

    const logoutInterface = () => {
        switch (process.env.GATSBY_REACT_APP_REGION) {
            case "es-CL":
            case "es-PE":
                return closeSession();
            default:
                return logout();
        }
    };

    const headerLinks = {
        "es-CL": [
            {
                url: getUrlPrivateSite(),
                span: "Mis inversiones",
                internal: false,
            },
            // TODO COMENTADO POR EL PILOTO
            // {
            //     url: `${Settings.secureUrl}/profiles/user`,
            //     span: "Configuración de perfil",
            //     internal: false,
            // },
        ],
        "es-MX": [
            {
                url: "https://secure.cumplo.mx/dashboard/inversiones",
                span: "Mis inversiones",
                internal: false,
            },
            {
                url: "https://secure.cumplo.mx/dashboard/perfil",
                span: "Configuración de perfil",
                internal: false,
            },
        ],
        "es-PE": [
            {
                url: getUrlPrivateSite(),
                span: "Mis inversiones",
                internal: false,
            },
        ],
    };

    if (isLoggedin.isLoggedin) {
        return (
            <>
                {headerLinks[process.env.GATSBY_REACT_APP_REGION].map(
                    (value) => {
                        if (!value.internal) {
                            return (
                                <a
                                    key={value.span}
                                    href={value.url}
                                    style={{ textDecoration: "none" }}
                                >
                                    <MenuItem
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                        key={value.url}
                                    >
                                        {value.span}
                                    </MenuItem>
                                </a>
                            );
                        }
                        return (
                            <a
                                key={value.span}
                                href={value.url}
                                style={{ textDecoration: "none" }}
                            >
                                <MenuItem
                                    sx={{ color: theme.palette.text.primary }}
                                    key={value.url}
                                >
                                    {value.span}
                                </MenuItem>
                            </a>
                        );
                    }
                )}
                <MenuItem
                    sx={{ color: theme.palette.text.primary }}
                    onClick={logoutInterface}
                >
                    Cerrar sesión
                </MenuItem>
            </>
        );
    } else if (window.domainData.countryCode === "CL") {
        return (
            <>
                <a
                    href={"https://inversionistas.cumplo.cl"}
                    style={{ textDecoration: "none" }}
                >
                    <MenuItem
                        sx={{ color: theme.palette.text.primary }}
                        onClick={logoutInterface}
                    >
                        Inversionistas
                    </MenuItem>
                </a>
                <a
                    href={"https://financiamiento.cumplo.cl"}
                    style={{ textDecoration: "none" }}
                >
                    <MenuItem
                        sx={{ color: theme.palette.text.primary }}
                        onClick={logoutInterface}
                    >
                        Solicitantes
                    </MenuItem>
                </a>
                <a
                    href={"https://prontopago.cumplo.cl"}
                    style={{ textDecoration: "none" }}
                >
                    <MenuItem
                        sx={{ color: theme.palette.text.primary }}
                        onClick={logoutInterface}
                    >
                        Corporaciones
                    </MenuItem>
                </a>
            </>
        );
    }
};
