import { graphql, useStaticQuery } from 'gatsby'

const GetSolutionsSliderCO = () => {

 const { allContentfulSlider } = useStaticQuery(graphql`
    {
       allContentfulSlider(filter: {name: {eq: "Slider de soluciones"}, node_locale: {eq: "es-CO"}}) {
         nodes {
           slides {
             title
             body {
               internal {
                 content
               }
             }
             backgroundImageDesktop {
               file {
                 url
               }
             }
             backgroundImageMobile {
               file {
                 url
               }
             }
             logo {
               file {
                 url
               }
             }
             features {
               icon
               title
             }
             callToAction {
              text
              link
            }
           }
         }
       }
     }
        `)
 const results = allContentfulSlider?.nodes[0]
   
 return results
   
}
   
export default GetSolutionsSliderCO
   
   