import { graphql, useStaticQuery } from 'gatsby'

const GetHowToEnterprisesFeaturesUS = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "¿Cómo funciona?"}, node_locale: {eq: "en-US"}}
    ) {
      nodes {
        title
        features {
          number
          title
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]

 return results

}

export default GetHowToEnterprisesFeaturesUS
