import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { fetch } from 'isomorphic-fetch'
import { resolvers } from './resolvers'
import Settings from './Settings'

const inMemoryCache = new InMemoryCache()

const initialState = {
 isLoggedIn: false,
}

inMemoryCache.writeData({
 data: initialState,
})

if (typeof window !== 'undefined') {
 persistCache({
  cache: inMemoryCache,
  storage: window.localStorage,
  maxSize: 4500000,
  debug: Settings.debug,
 })
}

const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null

const link = ApolloLink.from([
 createHttpLink({
  uri: process.env.GATSBY_APOLLO_URL,
  fetch,
  headers: {
   'Accept-Language': process.env.GATSBY_REACT_APP_REGION,
   Authorization: token,
  },
  credentials: 'include',
  fetchOptions: {
   mode: 'no-cors'
  },
 }),
])

export const client = new ApolloClient({
 ssrMode: typeof window === 'undefined',
 cache: inMemoryCache,
 resolvers,
 link,
})
