import { graphql, useStaticQuery } from 'gatsby'

const GetFeaturesImpactoMX = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures (
        filter: {name: {eq: "Qué es Impacto"}, node_locale: {eq: "es-MX"}}
    ){
      nodes {
        title
        features {
          number
          title
          description
          image {
            file {
                url
            }
          }
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetFeaturesImpactoMX
