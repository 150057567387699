import React, { createContext, useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import { useCompanyByInvestorId } from "../../hooks/useGetCompanies";
import { useGetBalanceByUser } from "../../hooks/useGetBalanceCumplo";
import Spinner from "../../shared/Spinner/Spinner";
import { session } from "cumplo_utils";
let TIMER_ID = null;

const SimulatorContext = createContext({
  selectedInvestmentData: {},
  setSelectedInvestmentData: () => { },
  user: null,
  setUser: () => { },
  selectedInvestorProfile: null,
  selectInvestorProfile: () => { },
  balanceCumplo: () => [],
  setBalanceCumplo: () => [],
  isLoading: false,
  setLoading: () => false,
  isAuth: false,
});

const SimulatorProvider = ({ children }) => {
  const [isEndSession, setEndSession] = useState(false);
  const [selectedInvestmentData, setSelectedInvestmentData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentSimulationAmount, setCurrentSimulationAmount] = useState(0);
  const [user, setUser] = useState(null);
  const [selectedInvestorProfile, selectInvestorProfile] = useState(null);
  const { response: profiles, getCompaniesByInvestorId, reset: resetCompanies, loading: loadingCompanies } = useCompanyByInvestorId();
  const { response: balanceCumplo, getBalanceByUser, reset: resetBalanceCumplo, loading: loadingBalance } = useGetBalanceByUser();
  const isAuth = !!user;

  // GET PROFILES
  useEffect(() => {
    if (user) {
      getCompaniesByInvestorId(user.id);
    } else {
      selectInvestorProfile(null);
      resetCompanies([]);
    }
  }, [user]);

  // SET PROFILE DEFAULT
  useEffect(() => {
    selectInvestorProfile(profiles[0]);
  }, [profiles]);

  // GET BALANCE CUMPLO
  useEffect(() => {
    if (selectedInvestorProfile) {
      getBalanceByUser(selectedInvestorProfile.numberId);
    } else {
      resetBalanceCumplo();
    }
  }, [selectedInvestorProfile]);

  const closeSession = () => {
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  }

  useEffect(() => {
    const { remmaning } = session.getExpiryDateSession();
    const handleTimeOver = () => setEndSession(true);

    if (user && !TIMER_ID) {
      TIMER_ID = setTimeout(handleTimeOver, remmaning);
    }
    return () => {
      TIMER_ID = null;
      clearTimeout(TIMER_ID);
    };
  }, [user]);

  return (
    <SimulatorContext.Provider
      value={{
        selectedInvestmentData,
        setSelectedInvestmentData,
        currentSimulationAmount,
        setCurrentSimulationAmount,
        user,
        setUser,
        selectedInvestorProfile,
        selectInvestorProfile,
        profiles,
        balanceCumplo,
        isLoading,
        setLoading,
        isAuth,
      }}
    >
      <Spinner isOpen={ isLoading || loadingBalance || loadingCompanies } />
      <Snackbar
        open={isEndSession}
        autoHideDuration={2000}
        onClose={closeSession}
        message="Sesión finalizada"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {children}
    </SimulatorContext.Provider>
  );
};

export { SimulatorContext, SimulatorProvider };
