import { graphql, useStaticQuery } from 'gatsby'

const GetProntoPagoFeaturesCO = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "Pronto Pago"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        features {
          icon
          title
          description
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetProntoPagoFeaturesCO
