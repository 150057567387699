import React from "react";
import { Typography, Box } from "@mui/material";
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';


const ContenidoTextoEnriquecidoNuevo = ({contenidos}) => {

 const theme = useTheme();

 const ImageAsset = ({ reference }) => <Box component="img" src={reference} sx={{ display: "flex", margin: "32px auto", maxWidth: "100%"}}/>;

 const Paragraph = styled(Typography)(({ theme }) => ({
  lineHeight: "auto",
  display: "block",
  marginBottom: 16
 }));

 const ParagraphLineBreak = styled(Typography)(({ theme }) => ({
  lineHeight: "auto",
  whiteSpace: "pre-wrap",
  display: "block",
  marginBottom: 2
 }));

 
 const options = {
  renderMark: {
   [MARKS.BOLD]: (text) => {
    return <span style={{fontWeight: 600}}>{text}</span>
   },
  },
  renderNode: {
   [BLOCKS.PARAGRAPH]: (node, children) => {
    var match = /\r|\n/.exec(children);
    if (match) {
     return <ParagraphLineBreak>{children}</ParagraphLineBreak>
    } else {
     return <Paragraph>{children}</Paragraph>
    }
   },
   [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
    let referenceURL = null
    contenidos?.references?.map((reference, index) => {
     if (reference?.contentful_id === node?.data?.target?.sys?.id) {
      return referenceURL = reference?.file?.url
     }
     return referenceURL
    })
    return <ImageAsset reference={referenceURL}>No son match</ImageAsset>
   },
   [INLINES.HYPERLINK]: (node, children) => {
    return (
     <a href={node?.data?.uri} 
      style={{ 
       display: "inline",
       color: theme.palette.secondary.main,
       fontWeight: 700,
       lineHeight: "auto",
       textDecoration: "none"
      }}
     >
      {node?.content[0]?.value}
     </a>
    )
   },
      
  },
 };

 return (
  <div>{documentToReactComponents(JSON.parse(contenidos?.raw), options)}</div>
 );
};

export { ContenidoTextoEnriquecidoNuevo };
export default ContenidoTextoEnriquecidoNuevo;