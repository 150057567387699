import { graphql, useStaticQuery } from 'gatsby'

const GetBenefitsBenefitsUS = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "Beneficios"}, node_locale: {eq: "en-US"}}
    ) {
      nodes {
        title
        features {
          icon
          title
          description
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]

 return results

}

export default GetBenefitsBenefitsUS
