import { useState } from "react";
import axios from "axios";
import {
    coerceCurrencyProperty,
    coerceStringProperty,
} from "../utils/coercion";
import { cookie } from "cumplo_utils";
import { addfixedHeaders } from "../utils/addFixedHeaders";

const host = process.env.GATSBY_HOST_URL;
const initialState = [];

export const useGetBalanceByUser = () => {
    const [response, setResponse] = useState(initialState);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const getBalanceByUserPromise = (investorId) => {
        const { value: jwt } = cookie.getCookie("cumploSession");
        const config = {
            headers: { Authorization: `Bearer ${jwt}`, ...addfixedHeaders() },
        };
        const country =
            typeof window !== "undefined" ? window.environmentCountry : "";
        const url = `${host}/${country}/participantes/${investorId}/saldos`;
        return axios.get(url, config);
    };

    const getBalanceByUser = (investorId) => {
        setLoading(true);
        setError("");
        getBalanceByUserPromise(investorId)
            .then(({ data }) => {
                const balances = (data?.data?.attributes?.saldos || []).map(
                    (x) => ({
                        countryCode: x.codigo_pais,
                        currencyCode: x.codigo_moneda,
                        balance: Number(x.saldo),
                        balance$: coerceCurrencyProperty(
                            x.saldo_inversionista,
                            x.codigo_moneda
                        ),
                        investorBalance: x.saldo_inversionista,
                        requestBalance: x.saldo_solicitante,
                        payerBalance: x.saldo_pagador,
                        debitBalance: x.saldo_deudor,
                    })
                );

                setResponse(balances);
                if (balances.length === 0) throw new Error("Error de servidor");
            })
            .catch((err) => {
                console.log(err);
                const textError = coerceStringProperty(
                    err?.response?.data?.error?.message,
                    "Error de servidor"
                );
                setError(textError);
                setResponse(initialState);
            })
            .finally(() => setLoading(false));
    };

    const reset = () => {
        setResponse(initialState);
    };

    return { response, loading, error, getBalanceByUser, reset };
};
