import { graphql, useStaticQuery } from 'gatsby'

const GetFAQSobreCumploCL = () => {

 const { allContentfulFaq } = useStaticQuery(graphql`
 {
  allContentfulFaq(
    sort: {fields: order, order: ASC},
    filter: {category: {eq: "Sobre Cumplo"}, node_locale: {eq: "es-CL"}}
  ) {
    nodes {
      title
      body {
        raw
        references {
          file {
            url
          }
          contentful_id
        }
      }
    }
  }
}
        `)
 const results = allContentfulFaq?.nodes
   
 return results
   
}
   
export default GetFAQSobreCumploCL