export const COUNTRY = [
    {
        name: "México",
        currencyCode: "MXN",
        locale: "es-MX",
        countryCode: "MX",
        currencyName: "Pesos",
        currencySymbol: "$",
    },
    {
        name: "Chile",
        currencyCode: "CLP",
        locale: "es-CL",
        countryCode: "CL",
        currencyName: "Pesos",
        currencySymbol: "$",
    },
    // TODO START PARCHE
    {
        name: "Chile",
        currencyCode: "CL",
        locale: "es-CL",
        countryCode: "CL",
        currencyName: "Pesos",
        currencySymbol: "$",
    },
    // TODO END PARCHE
    {
        name: "Peru",
        currencyCode: "PEN",
        locale: "es-PE",
        countryCode: "PE",
        currencyName: "Soles",
        currencySymbol: "S/",
    },
    {
        name: "Peru",
        currencyCode: "USD",
        locale: "en-US",
        countryCode: "PE",
        currencyName: "Dólares",
        currencySymbol: "$",
    },
];
