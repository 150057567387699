import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Divider, useMediaQuery, Grid } from "@mui/material";

// import { Paper } from "src/atoms/index.jsx";
import Paper from '@mui/material/Paper';
import useStyles from "./styles";

/**
 * Componente que puede incluir varios pasos con informaciÃ³n e iconos
 */
const Steps = ({ steps, variant, ...props }) => {
 const classes = useStyles();
 const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

 return (
  <Paper className={classes.root} {...props}>
   <Grid container>
    {steps.map((step, i) => (
     <Fragment key={step.title}>
      <Grid
       item
       xs={12}
       md={steps.length === 4 ? 3 : steps.length === 2 ? 6 : 12}
       display={md ? "block" : "flex"}
      >
       <Box className={classes.stepContainer}>
        <Box className={classes.iconContainer}>
         {variant === "number" ? (
          <Box className={classes.numberContainer}>
           <Typography variant="h1" className={classes.number}>
            {step.number}
           </Typography>
          </Box>
         ) : (
          <></>
         )}
        </Box>
        <Box className={classes.content}>
         <Box>
          <Typography variant="h4" className={classes.title}>
           {step.title}
          </Typography>
         </Box>
         <Box>
          <Typography variant="body1" className={classes.body}>
           {step.body}
          </Typography>
         </Box>
        </Box>
       </Box>
       {i !== steps.length - 1 && steps.length > 1 && (
        <Divider
         className={classes.divider}
         orientation={md ? "horizontal" : "vertical"}
         flexItem
        />
       )}
      </Grid>
     </Fragment>
    ))}
   </Grid>
  </Paper>
 );
};

Steps.propTypes = {
 /** Tipo de step que se mostrarÃ¡, sea con base a iconos o con nÃºmeros */
 variant: PropTypes.oneOf(["icon", "number"]),
 /** Cada paso contiene: 
  1. TÃ­tulo del paso
  2. Texto del cuerpo de la tarjeta
  3. Nombre del icono que se quiere usar
  4. NÃºmero de paso
  */
 steps: PropTypes.arrayOf(
  PropTypes.shape({
   title: PropTypes.string,
   body: PropTypes.string,
   icon: PropTypes.string,
   number: PropTypes.number,
  })
 ),
};

Steps.defaultProps = {
 variant: "icon",
 steps: [],
};

export { Steps };
export default Steps;