import { graphql, useStaticQuery } from 'gatsby'

const GetHowToEnterprisesFeaturesCO = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures(
      filter: {name: {eq: "¿Cómo funciona?"}, node_locale: {eq: "es-CO"}}
    ) {
      nodes {
        title
        features {
          number
          title
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetHowToEnterprisesFeaturesCO
