import { graphql, useStaticQuery } from 'gatsby'

const GetFeaturesHowToMX = () => {

 const { allContentfulFeatures } = useStaticQuery(graphql`
 {
    allContentfulFeatures (
        filter: {name: {eq: "Pasos para unirte a la red Cumplo"}, node_locale: {eq: "es-MX"}}
    ){
      nodes {
        title
        features {
          number
          title
          body: description
        }
      }
    }
  }
        `)
 const results = allContentfulFeatures?.nodes[0]
   
 return results
   
}
   
export default GetFeaturesHowToMX
