import React, { useState, useEffect, useContext } from "react";
import { SimulatorContext } from "../../Simulator/SimulatorContext";
import { useGetUserMe } from "../../../hooks/useGetUserMe";
import "src/assets/styles/global-styles.css";
import { ThemeProvider as ThemeProviderOld } from "@mui/material/styles";
import { CumploBranding } from "templates";
import CssBaseline from "@mui/material/CssBaseline";
import { Layout, ThemeProvider } from "@cumplo/design-system";
import { Footer } from "src/components/common/Footer";
import Cookies from "universal-cookie";
import { session } from "cumplo_utils";
import { Header } from "src/components/common/Header";
import { navigate } from "gatsby";
import { getUrlPrivateSite } from "../../../utils/privateSite";
import { isCountryMexico } from "../../../utils/country";

const TopLayout = ({ children }) => {
    const {
        selectedInvestorProfile,
        selectInvestorProfile,
        profiles,
        setUser,
    } = useContext(SimulatorContext);
    const { response: user, getUserMe } = useGetUserMe();
    const [returnPath, setReturnPath] = useState("");
    const cookies = new Cookies();

    if (cookies.get("preventFraudAnnouncement") === undefined) {
        cookies.set("preventFraudAnnouncement", true, { path: "/" });
    }

    const loggedIn = session.isLogged();
    const code = process.env.GATSBY_REACT_APP_REGION.slice(-2);

    useEffect(() => {
        const currentPath = window.location.pathname;
        const destiny =
            currentPath.replace(/\//g, "") == "form"
                ? "/login/?destiny=" + window.location.search.split("=")[1]
                : "";

        setReturnPath(destiny);

        if (loggedIn) {
            getUserMe();
        } else {
            setUser(null);
        }
    }, [loggedIn]);

    useEffect(() => {
        if (user) {
            setUser(user);
        }
    }, [user]);

    const changeInvestorProfile = (profileId) => {
        const id = profiles.filter((item) => item.id == profileId)[0];

        selectInvestorProfile(id);
    };

    const closeSession = () => {
        session.closeSession();
        window.location.reload();
    };

    const logoutInterface = () => {
        const regions = ["es-CL", "es-PE"];

        if (regions.includes(window?.domainData?.region)) {
            return closeSession();
        } else {
            return "";
        }
    };

    const handleNavigation = () => {
        const url = getUrlPrivateSite();

        navigate(`${url}/`);
    };

    const loginLinks =
        process.env.GATSBY_REACT_APP_REGION === "es-MX"
            ? [
                  {
                      label: "Inversionistas",
                      href: "https://sofom.cumplo.mx",
                  },
              ]
            : [
                  {
                      label: "Inversionistas",
                      href: getUrlPrivateSite() + returnPath,
                  },
                  {
                      label: "Financiamiento",
                      href: process.env.GATSBY_URL_FINANCING + code,
                  },
                  {
                      label: "Gestionar pagos",
                      href: "https://prontopago.cumplo." + code,
                  },
              ];

    const signUplinksProps =
        process.env.GATSBY_REACT_APP_REGION === "es-MX"
            ? {}
            : {
                  signUpLinks: [
                      {
                          label: "Inversionistas",
                          href: getUrlPrivateSite() + "/registro",
                      },
                      {
                          label: "Financiamiento",
                          href:
                              process.env.GATSBY_URL_FINANCING +
                              code +
                              "/registro",
                      },
                      {
                          label: "Gestionar pagos",
                          href:
                              "https://prontopago.cumplo." + code + "/registro",
                      },
                  ],
              };
    return (
        <div>
            <CssBaseline />
            <ThemeProvider>
                <Layout
                    title="Financiamiento"
                    userName={selectedInvestorProfile?.name}
                    enterpriseName={selectedInvestorProfile?.name}
                    enterprises={profiles}
                    type="investment"
                    onLogout={() => logoutInterface()}
                    onAddEnterprise={() => {
                        handleNavigation();
                    }}
                    onSelectEnterprise={(e) => changeInvestorProfile(e)}
                    publicSite={true}
                    environment="production"
                    loggedIn={loggedIn}
                    loginLinks={loginLinks}
                    homeUrl={"https://cumplo." + code}
                    locale={process.env.GATSBY_REACT_APP_REGION}
                    privateSiteUrl={getUrlPrivateSite()}
                    {...signUplinksProps}
                >
                    <ThemeProviderOld theme={CumploBranding}>
                        {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
                            <Header />
                        )}
                        {children}
                    </ThemeProviderOld>
                </Layout>
            </ThemeProvider>
            <ThemeProviderOld theme={CumploBranding}>
                <Footer />
            </ThemeProviderOld>
        </div>
    );
};

export { TopLayout };
