import { graphql, useStaticQuery } from 'gatsby'

const GetTestimonialsCarrouselHomeCO = () => {

 const { allContentfulCarrusel } = useStaticQuery(graphql`
 {
    allContentfulCarrusel (
        filter: {name: {eq: "Carrusel de testimoniales - Home"}, node_locale: {eq: "es-CO"}}
      ) {
      nodes {
        testimonials {
          photo {
            file {
              url
            }
          }
          name
          position
          smallQuote
          longQuote {
            internal {
              content
            }
          }
        }
        title
      }
    }
  }
        `)
 const results = allContentfulCarrusel?.nodes[0]
   
 return results
   
}
   
export default GetTestimonialsCarrouselHomeCO
   
   