const getSecureUrl = () => {
 switch (process.env.GATSBY_REACT_APP_REGION) {
 case ('es-CL'):
  return process.env.GATSBY_SECURE_SITIO;
 case ('es-MX'):
  return 'https://secure.cumplo.mx'
 default:
  return process.env.GATSBY_SECURE_SITIO;
 }
}

const gatsbyActiveEnv = process.env.GATSBY_ACTIVE_ENV
const secureUrl = getSecureUrl()
const debug = gatsbyActiveEnv === 'local'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
 gatsbyActiveEnv,
 secureUrl,
 debug,
 urlApollo: 'https://ffh86djgol.execute-api.us-east-1.amazonaws.com/development/graphql',
 sentry: 'https://ae7b45458cdd4076a87a6f80a81f0a11@sentry.io/5181814',
 locale: process.env.GATSBY_REACT_APP_REGION,
 currency: process.env.GATSBY_REACT_APP_REGION === "es-CL" ? 'CLP' : process.env.GATSBY_REACT_APP_REGION === "es-PE" ? "PEN" : 'MXN',
 urlPromissoryNoteDownload: (id) => `https://${secureUrl}/api/investments/${id}/promissory_note`,
}
