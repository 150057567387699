import React from "react";
import { useTheme } from "@mui/material/styles";
import { Icon } from "src/templates";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, Box, ThemeProvider, Button } from "@cumplo/design-system";
import { getUrlCreateAccount } from "../../../utils/privateSite";
import { isCountryPeru } from "../../../utils/country";

const InfoMessage = ({ body }) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("md"));
    const md = useMediaQuery(theme.breakpoints.down("lg"));

    const styles = {
        infoMessage: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#E8EFFD",
            padding: "4px",
        },
    };

    return (
        <ThemeProvider>
            <Box sx={styles.infoMessage}>
                <Box padding={2}>
                    <Icon
                        name={"info"}
                        height="20px"
                        width="20px"
                        color={theme.palette.info.main}
                    />
                </Box>
                <Typography
                    variant="body2"
                    fontSize={
                        sm
                            ? "12px !important"
                            : md
                            ? "14px !important"
                            : "16px !important"
                    }
                    pr={2}
                    pt={1}
                    pb={1}
                >
                    {body}
                </Typography>

                {/* Comentado porque no es necesario por el momento
                {isCountryPeru() && (
                    <>
                        <Typography
                            variant="body2"
                            fontSize={
                                sm
                                    ? "12px !important"
                                    : md
                                    ? "14px !important"
                                    : "16px !important"
                            }
                            pr={2}
                            pt={1}
                            pb={1}
                        >
                            Si aún no tienes cuenta para invertir
                        </Typography>
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="small"
                        >
                            <a
                                style={{
                                    textDecoration: "none",
                                    color: "#555F6D",
                                }}
                                href={getUrlCreateAccount()}
                            >
                                Regístrate
                            </a>
                        </Button>
                    </>
                )} */}
            </Box>
        </ThemeProvider>
    );
};

export { InfoMessage };
