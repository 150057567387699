import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import {
    ElevateAppBar,
    Logo,
    Button,
    CountrySelector,
    ExpandableMenu,
    Icon,
} from "src/templates";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import { Divider, MenuItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { Location } from "@reach/router";
import ScrollToTop from "react-scroll-to-top";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { getContent } from "src/queries";
import { Popup } from "src/templates";
import { UserMenu } from "src/components/common/UserMenu";
import { SimulatorContext } from "../../Simulator/SimulatorContext";
import * as styles from "./styles.module.css";
import { useGetUserMe } from "../../../hooks/useGetUserMe";
import { session } from "cumplo_utils";
import { getUrlPrivateSite } from "../../../utils/privateSite";

const Header = () => {
    const {
        selectedInvestorProfile,
        selectInvestorProfile,
        profiles,
        setUser,
    } = useContext(SimulatorContext);
    const { response: user, getUserMe } = useGetUserMe();

    const theme = useTheme();
    const xl = useMediaQuery(theme.breakpoints.down("xl"));
    const lg = useMediaQuery(theme.breakpoints.down("lg"));
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const [drawer, setDrawer] = useState(false);
    const region = process.env.GATSBY_REACT_APP_REGION;
    const headerNavigationBarResults = getContent(
        "header-navigation-bar",
        region
    );
    const isCountryChile = region === "es-CL";
    const [anchorEl, setAnchorEl] = useState(null);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const loggedIn = session.isLogged();

    const handleMenu = () => {
        const element = document.querySelector("#linksMenu");
        let display = "block";

        if (element.style.display === "none" || element.style.display === "") {
            display = "block";
        } else {
            display = "none";
        }

        document.querySelector("#linksMenu").style = "display:" + display;
    };

    let loginLink = "";

    if (region === "es-PE" || region === "es-CL") {
        loginLink = getUrlPrivateSite();
    } else {
        loginLink = headerNavigationBarResults?.callToAction?.link;
    }

    const changeInvestorProfile = (profile) => {
        selectInvestorProfile(profile);
    };

    useEffect(() => {
        if (loggedIn) {
            getUserMe();
        } else {
            setUser(null);
        }
    }, [loggedIn]);

    useEffect(() => {
        if (user) {
            setUser(user);
        }
    }, [user]);

    const navigateLogin = () => {
        if (window !== "undefined") {
            window.location = loginLink;
        }
    };

    return (
        <ElevateAppBar>
            <Container>
                {process.env.GATSBY_REACT_APP_REGION === "es-PE" && (
                    <Location>
                        {({ location }) =>
                            (location?.pathname !==
                                "/terminos-y-regulaciones/" ||
                                location?.pathname !==
                                    "/terminos-y-regulaciones") && <Popup />
                        }
                    </Location>
                )}
                {md && (
                    <ScrollToTop
                        smooth
                        style={{ background: "transparent", right: "10px" }}
                        component={
                            <Box
                                sx={{
                                    marginTop: "-8px",
                                    marginLeft: "-8px",
                                    position: "relative",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background:
                                            theme.palette.secondary.main,
                                        borderRadius: "16px",
                                        height: 50,
                                        width: 50,
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    <ArrowUpwardIcon
                                        color={theme.palette.text.secondary}
                                    />
                                </Box>
                            </Box>
                        }
                    />
                )}
                <Box
                    sx={{
                        display: "flex !important",
                        justifyContent: "space-between !important",
                        width: "100% !important",
                        alignItems: "center !important",
                    }}
                >
                    {lg && (
                        <Box sx={{ minWidth: 102 }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                                onClick={() => setDrawer(!drawer)}
                            >
                                <Icon
                                    name="menuIcon"
                                    height="40px"
                                    color={theme.palette.secondary.main}
                                />
                            </IconButton>
                        </Box>
                    )}
                    <Box sx={{ lineHeight: 0 }}>
                        <a href="/">
                            <Logo
                                variant={md ? "isotype" : "full"}
                                size={!xl ? 200 : !lg ? 175 : !md ? 120 : 34}
                                color={theme.palette.primary.main}
                            />
                        </a>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            alignItems: "flex-end",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "inline-flex",
                                gap: 3,
                                alignItems: "center",
                            }}
                        >
                            {/* Está logueado (es 1) */}

                            <>
                                {loggedIn && (
                                    <IconButton
                                        style={{
                                            padding: 0,
                                            width: 36,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginLeft: "66px",
                                        }}
                                        aria-label="open user-profile"
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                            setShowUserMenu(!showUserMenu);
                                        }}
                                    >
                                        <Icon
                                            name="accountCircleIcon"
                                            height="36px"
                                            color={theme.palette.secondary.main}
                                        />
                                    </IconButton>
                                )}
                                {!loggedIn &&
                                    headerNavigationBarResults?.callToAction && (
                                        <Button
                                            icon={
                                                lg
                                                    ? null
                                                    : "accountCircleOutlinedIcon"
                                            }
                                            variant={lg ? "text" : "contained"}
                                            id="ingresar-btn"
                                            onClick={(event) => {
                                                if (!loggedIn && loginLink && window.domainData.countryCode !== "CL") {
                                                    navigateLogin();
                                                    return;
                                                }
                                                setAnchorEl(
                                                    event.currentTarget
                                                );
                                                setShowUserMenu(!showUserMenu);
                                            }}
                                        >
                                            {
                                                headerNavigationBarResults
                                                    ?.callToAction?.text
                                            }
                                        </Button>
                                    )}
                                <Menu
                                    open={showUserMenu}
                                    elevation={4}
                                    getContentAnchorEl={null}
                                    anchorEl={anchorEl}
                                    onClose={() => setShowUserMenu(false)}
                                    onClick={() => setShowUserMenu(false)}
                                    anchorOrigin={{
                                        vertical: md ? 62 - 15 : 40,
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    {isCountryChile && loggedIn && (
                                        <>
                                            <MenuItem
                                                sx={{
                                                    color: theme.palette.text
                                                        .primary,
                                                }}
                                                disabled
                                            >
                                                Seleccionar inversionista
                                            </MenuItem>
                                            {/* PERFILES DE INVERSIÓN */}
                                            {profiles.map((x) => (
                                                <MenuItem
                                                    sx={{
                                                        color: theme.palette
                                                            .text.primary,
                                                    }}
                                                    selected={
                                                        selectedInvestorProfile?.id ===
                                                        x.id
                                                    }
                                                    onClick={() =>
                                                        changeInvestorProfile(x)
                                                    }
                                                >
                                                    {x.name}
                                                </MenuItem>
                                            ))}
                                            <Divider />
                                        </>
                                    )}
                                    <UserMenu isLoggedin={loggedIn} />
                                </Menu>
                            </>

                            {/* No hay información sobre login */}

                            {!loggedIn && <div />}

                            {!lg &&
                                headerNavigationBarResults?.countrySelector && (
                                    <Box>
                                        <CountrySelector
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                        />
                                    </Box>
                                )}
                        </Box>

                        {!lg && headerNavigationBarResults?.navigationLinks && (
                            <Box
                                sx={{
                                    display: "inline-flex",
                                    gap: 3,
                                    alignItems: "center",
                                }}
                            >
                                <Location>
                                    {({ location }) =>
                                        headerNavigationBarResults?.navigationLinks.map(
                                            (navigationLink, index) => (
                                                <ExpandableMenu
                                                    location={location}
                                                    key={index}
                                                    text={navigationLink?.text}
                                                    link={navigationLink?.link}
                                                    dropdownLinks={
                                                        navigationLink?.dropdownLinks
                                                    }
                                                />
                                            )
                                        )
                                    }
                                </Location>
                            </Box>
                        )}
                    </Box>
                    {/* Mobile menu */}
                    <SwipeableDrawer
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        onOpen={() => setDrawer(true)}
                        PaperProps={{
                            sx: {
                                background: theme.palette.text.secondary,
                            },
                        }}
                    >
                        <Box sx={{ height: "inherit", padding: "30px" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: 2,
                                }}
                            >
                                <Logo
                                    variant={"full"}
                                    size="125px"
                                    color={theme.palette.primary.main}
                                />
                                <Box
                                    onClick={() => setDrawer(false)}
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <Icon
                                        name="close"
                                        color={theme.palette.secondary.main}
                                        height="40px"
                                    />
                                </Box>
                            </Box>
                            <List>
                                <a
                                    href="/"
                                    style={{
                                        color: theme.palette.text.secondary,
                                        textDecoration: "none",
                                    }}
                                >
                                    <Location>
                                        {({ location }) =>
                                            location?.pathname === "/" && (
                                                <Box
                                                    sx={{
                                                        marginLeft: "4px",
                                                        marginBottom: "-4px",
                                                        display: "inline-block",
                                                        height: "18px",
                                                        width: "4px",
                                                        background:
                                                            theme.palette
                                                                .secondary.dark,
                                                        borderRadius: "18px",
                                                    }}
                                                />
                                            )
                                        }
                                    </Location>
                                    <ListItem
                                        button
                                        sx={{
                                            paddingLeft: "4px",
                                            display: "inline-block",
                                        }}
                                    >
                                        <ListItemText
                                            primary="Inicio"
                                            primaryTypographyProps={{
                                                variant: "button",
                                                color: "secondary.main",
                                            }}
                                        />
                                    </ListItem>
                                </a>
                                {/* Links clickeables sin dropdown */}
                                {headerNavigationBarResults?.navigationLinks.map(
                                    (navigationLink, index) => (
                                        <div key={index}>
                                            {navigationLink.dropdownLinks ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderTop: `1px solid ${theme.palette.primary.main}`,
                                                        marginTop: "12px",
                                                        paddingTop: "12px",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="primary.dark"
                                                        sx={{
                                                            opacity: 1,
                                                            padding:
                                                                "13px 0px 13px 4px",
                                                            cursor: "default",
                                                            display:
                                                                "inline-block",
                                                        }}
                                                    >
                                                        {navigationLink?.text}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <a
                                                    key={index}
                                                    href={navigationLink?.link}
                                                    style={{
                                                        color: theme.palette
                                                            .text.primary,
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <Location>
                                                        {({ location }) =>
                                                            location?.pathname ===
                                                                navigationLink?.link +
                                                                    "/" && (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            marginLeft:
                                                                                "4px",
                                                                            marginBottom:
                                                                                "-4px",
                                                                            display:
                                                                                "inline-block",
                                                                            height: "18px",
                                                                            width: "4px",
                                                                            background:
                                                                                theme
                                                                                    .palette
                                                                                    .secondary
                                                                                    .dark,
                                                                            borderRadius:
                                                                                "18px",
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    </Location>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            borderTop: `1px solid ${theme.palette.primary.main}`,
                                                            marginTop: "12px",
                                                            paddingTop: "12px",
                                                        }}
                                                    >
                                                        <ListItem
                                                            button
                                                            key={
                                                                navigationLink?.text
                                                            }
                                                            sx={{
                                                                paddingLeft:
                                                                    "4px",
                                                                display:
                                                                    "inline-block",
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    navigationLink?.text
                                                                }
                                                                primaryTypographyProps={{
                                                                    variant:
                                                                        "button",
                                                                    color: "secondary.main",
                                                                }}
                                                            />
                                                        </ListItem>
                                                    </Box>
                                                </a>
                                            )}
                                            {/* Links clickeables del dropdown */}
                                            {navigationLink?.dropdownLinks?.map(
                                                (item, index) => (
                                                    <a
                                                        key={index}
                                                        href={item?.link}
                                                        style={{
                                                            color: theme.palette
                                                                .text.secondary,
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        <Location>
                                                            {({ location }) =>
                                                                location?.pathname ===
                                                                item?.link ? (
                                                                    <>
                                                                        <Box
                                                                            sx={{
                                                                                marginLeft:
                                                                                    "4px",
                                                                                marginBottom:
                                                                                    "-4px",
                                                                                display:
                                                                                    "inline-block",
                                                                                height: "18px",
                                                                                width: "4px",
                                                                                background:
                                                                                    theme
                                                                                        .palette
                                                                                        .secondary
                                                                                        .dark,
                                                                                borderRadius:
                                                                                    "18px",
                                                                            }}
                                                                        />
                                                                        <ListItem
                                                                            button
                                                                            key={
                                                                                item?.text
                                                                            }
                                                                            sx={{
                                                                                paddingLeft:
                                                                                    "4px",
                                                                                display:
                                                                                    "inline-block",
                                                                            }}
                                                                        >
                                                                            <ListItemText
                                                                                primary={
                                                                                    item?.text
                                                                                }
                                                                                primaryTypographyProps={{
                                                                                    variant:
                                                                                        "button",
                                                                                    color: "secondary.main",
                                                                                }}
                                                                            />
                                                                        </ListItem>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <ListItem
                                                                            button
                                                                            key={
                                                                                item?.text
                                                                            }
                                                                            sx={{
                                                                                paddingLeft:
                                                                                    "4px",
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                        >
                                                                            <ListItemText
                                                                                primary={
                                                                                    item?.text
                                                                                }
                                                                                primaryTypographyProps={{
                                                                                    variant:
                                                                                        "button",
                                                                                    color: "secondary.main",
                                                                                }}
                                                                            />
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            }
                                                        </Location>
                                                    </a>
                                                )
                                            )}
                                        </div>
                                    )
                                )}
                                <ListItem
                                    button
                                    sx={{
                                        width: "fix-content !important",
                                        paddingLeft: "4px",
                                    }}
                                >
                                    <ListItemText
                                        primary="Estás en Cumplo"
                                        primaryTypographyProps={{
                                            variant: "body1",
                                            color: "text.primary",
                                        }}
                                    />
                                    <Box sx={{ paddingLeft: 2 }}>
                                        <CountrySelector noBorder />
                                    </Box>
                                </ListItem>
                            </List>
                        </Box>
                    </SwipeableDrawer>
                </Box>
            </Container>
        </ElevateAppBar>
    );
};

export { Header };
